import {
  YOUTUBE_FEED_SET_SLACK_WEBHOOK,
  YOUTUBE_FEED_SET_SLACK_MIN_SUBSCRIBERS,
  YOUTUBE_FEED_FETCH_FEED_INFO_SUCCESS,
} from '../../actions/actionTypes';

const initialState = {
  slack_webhook_url: null,
  slack_min_subs_notification: null,
};

const slack = (state = initialState, action) => {
  switch (action.type) {
    case YOUTUBE_FEED_FETCH_FEED_INFO_SUCCESS:
      return {
        ...state,
        slack_min_subs_notification: action.payload?.webhook?.slack_min_subs_notification,
        slack_webhook_url: action.payload?.webhook?.slack_webhook,
      };
    case YOUTUBE_FEED_SET_SLACK_WEBHOOK:
      return { ...state, slack_webhook_url: action.payload };
    case YOUTUBE_FEED_SET_SLACK_MIN_SUBSCRIBERS:
      return { ...state, slack_min_subs_notification: action.payload };
    default:
      return state;
  }
};

export default slack;
