import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import SavedStreamSaveNewStreamModalBody from './SavedStreamSaveNewStreamModalBody.jsx';
import useSaveVideoMutation from './useSaveStreamMutation.js';
import { savedStreamsFetch } from '../../../../actions/savedStreams.js';
import translate from '../../../../helpers/translate.js';
import { getCurrentProjectId } from '../../../../selectors/forms.js';
import { getNotLockedTags } from '../../../../selectors/tags.js';

const SavedStreamsSaveNewVideoModal = ({ isOpen, onClose, tags, projectId }) => {
  const [videoURL, setStreamURL] = useState(null);
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const onSuccess = () => {
    onClose();
    savedStreamsFetch({ project_id: projectId, pagination: { sizePerPage: 10, page: 1, sortField: 'published_at' } });
  };

  const mutation = useSaveVideoMutation(onSuccess);

  useEffect(
    () => () => {
      setStreamURL(null);
      setSelectedTags([]);
      setIsUrlValid(false);
    },
    [],
  );

  const handleMutation = () => {
    mutation.mutate({ stream_url: videoURL, tag_id: selectedTags });
  };

  const isSaveEnabled = isUrlValid && selectedTags.length > 0;

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
      <ModalHeader className="fp-modal-header--small">
        {translate('pages.saved_streams.save_new_stream.save_new_video')}
      </ModalHeader>
      <ModalBody className="fp-modal-body">
        <SavedStreamSaveNewStreamModalBody
          setIsUrlValid={setIsUrlValid}
          setVideoURL={setStreamURL}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          isUrlValid={isUrlValid}
          videoURL={videoURL}
          tags={tags}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close">
          {translate('common.close')}
        </Button>
        <Button
          size="sm"
          type="submit"
          color="primary"
          name="saveButton"
          disabled={!isSaveEnabled}
          onClick={handleMutation}
        >
          {mutation.isLoading ? translate('common.loading') : translate('common.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  tags: getNotLockedTags(state),
  projectId: getCurrentProjectId(state),
});

export default connect(mapStateToProps, {
  savedStreamsFetch,
})(SavedStreamsSaveNewVideoModal);
