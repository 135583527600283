import React from 'react';

import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { twitchFeedSetSlackWebhook, twitchFeedSetSlackWebhookMinSubscribers } from '../../../actions/twitchFeed';
import translate from '../../../helpers/translate';
import {
  getTwitchFeedSlackWebhookMinSubscribers,
  getTwitchFeedSlackWebhookURL,
} from '../../../selectors/feeds/twitchFeed';

const SlackNotificationsForm = ({
  twitchFeedSetSlackWebhook,
  twitchFeedSetSlackWebhookMinSubscribers,
  slackWebhookUrl,
  slackWebhookMinSubscribers,
}) => {
  const handleChangeSlackWebhook = (value) => {
    twitchFeedSetSlackWebhook(value);
  };

  const handleChangeSlackSubscribers = (value) => {
    twitchFeedSetSlackWebhookMinSubscribers(value);
  };

  return (
    <FormGroup>
      <Row>
        <Col xs={6}>
          <Label htmlFor="slackWebhookInput">{translate('pages.twitch_feed.modals.slack_input.label')}</Label>
          <Input
            placeholder={translate('pages.twitch_feed.modals.slack_input.placeholder')}
            name="slackWebhookInput"
            onChange={(event) => handleChangeSlackWebhook(event.target.value)}
            defaultValue={slackWebhookUrl ?? null}
          />
        </Col>
        <Col xs={6}>
          <Label htmlFor="slackWebhookSubscribers">
            {translate('pages.twitch_feed.modals.slack_subscribers_input.label')}
          </Label>
          <Input
            placeholder={translate('pages.twitch_feed.modals.slack_subscribers_input.placeholder')}
            name="slackWebhookSubscribers"
            onChange={(event) => handleChangeSlackSubscribers(event.target.value)}
            defaultValue={slackWebhookMinSubscribers ?? null}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <span className="text-gray">
            {translate('pages.twitch_feed.modals.slack_webhook_guide')}
            <a
              href="https://api.slack.com/messaging/webhooks"
              target="_blank"
              rel="noreferrer"
              aria-label="Slack webhook guide"
            >
              {translate('pages.twitch_feed.modals.slack_webhook_guide_link')}
            </a>
            {translate('pages.twitch_feed.modals.slack_webhook_guide_2')}
          </span>
        </Col>
      </Row>
    </FormGroup>
  );
};

const mapStateToProps = (state) => ({
  slackWebhookUrl: getTwitchFeedSlackWebhookURL(state),
  slackWebhookMinSubscribers: getTwitchFeedSlackWebhookMinSubscribers(state),
});

export default connect(mapStateToProps, { twitchFeedSetSlackWebhookMinSubscribers, twitchFeedSetSlackWebhook })(
  SlackNotificationsForm,
);
