import { YOUTUBE_FEED_FETCH_FEED_INFO_SUCCESS, YOUTUBE_FEEDS_SET_NOTIFICATION_STATUS } from '../../actions/actionTypes';

const initialState = false;

const areNotificationsEnabled = (state = initialState, action) => {
  switch (action.type) {
    case YOUTUBE_FEED_FETCH_FEED_INFO_SUCCESS:
      return action.payload.webhook?.is_enabled ?? null;
    case YOUTUBE_FEEDS_SET_NOTIFICATION_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export default areNotificationsEnabled;
