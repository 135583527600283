import {
  REQUEST_CLIENTS,
  RECEIVE_CLIENTS,
  REJECT_CLIENTS,
  REQUEST_SAVE_CLIENT,
  RECEIVE_SAVE_CLIENT,
  REJECT_SAVE_CLIENT,
} from '../actions/clients';
import { apiReceive, apiRequest, apiReject } from '../config/api-service';

const clients = (
  state = {
    items: {},
    itemsStatus: {},
    lists: {},
    listsStatus: {},
    stats: {},
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_CLIENTS:
    case REQUEST_SAVE_CLIENT:
      return apiRequest(state, action);
    case RECEIVE_CLIENTS:
    case RECEIVE_SAVE_CLIENT:
      return apiReceive(state, action);
    case REJECT_CLIENTS:
    case REJECT_SAVE_CLIENT:
      return apiReject(state, action);
    default:
      return state;
  }
};

export default clients;
