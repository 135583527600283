import React, { useEffect } from 'react';

import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import SingleSelect from '../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../helpers/translate';

const validateYouTubeUrl = (url) => {
  const youtubeRegex = /(?:\/watch\?v=|youtu.be\/)([\w-]+)/;
  return youtubeRegex.test(url);
};

const SavedVideosSaveNewVideoModalBody = ({
  setVideoURL,
  setIsUrlValid,
  setSelectedTags,
  videoURL,
  selectedTags,
  isUrlValid,
  tags,
}) => {
  useEffect(
    () => () => {
      setIsUrlValid(false);
      setVideoURL(null);
      setSelectedTags([]);
    },
    [setIsUrlValid, setVideoURL, setSelectedTags],
  );

  const handleChangeInput = (value) => {
    if (value) {
      setVideoURL(value);
      setIsUrlValid(validateYouTubeUrl(value));
    } else {
      setVideoURL(null);
      setIsUrlValid(false);
    }
  };

  const handleOnChangeTag = (tag) => {
    if (tag) {
      setSelectedTags(tag.value);
    } else {
      setSelectedTags(null);
    }
  };

  return (
    <FormGroup>
      <Row>
        <Col>
          <Label for="video">{translate('pages.saved_videos.table.save_new_video.form.video_label')}</Label>
          <Input
            onChange={(event) => handleChangeInput(event.target.value)}
            value={videoURL}
            id="video"
            placeholder="Add your link here"
            valid={videoURL && isUrlValid}
            invalid={videoURL && !isUrlValid}
          />
          {videoURL && !isUrlValid && (
            <div className="invalid-feedback">
              {translate('pages.saved_videos.table.save_new_video.form.invalid_message')}
            </div>
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Label for="tags">{translate('common.tags')}</Label>
          <SingleSelect
            name="tags"
            items={tags}
            value={selectedTags ?? []}
            className="sm-select"
            placeholder={translate('common.all_tags')}
            onChange={handleOnChangeTag}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default SavedVideosSaveNewVideoModalBody;
