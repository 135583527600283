import React, { useEffect, useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { fetchClients } from '../actions/clients';
import { changeGlobalForm } from '../actions/forms';
import { changeModule } from '../actions/modules';
import { fetchProducts } from '../actions/products';
import { fetchProjects } from '../actions/projects';
import { tourSetTourIsRunning } from '../actions/tour';
import { fetchCurrentUser, receiveUserLogin } from '../actions/users';
import { STATUS } from '../config/api-service';
import { getIsFirstLogin, setIsFirstLogin, setSession } from '../helpers/localStorage';
import { getLoadingModule } from '../selectors/modules';
import { getCurrentProject, getProjects } from '../selectors/projects';
import { getUsersCurrentUser, getUsersCurrentUserDefaultProjectId } from '../selectors/users';

const LoginCallback = ({
  onLoad,
  onAuth,
  onLogin,
  preloadComplete,
  projects,
  moduleUser,
  moduleStatus,
  tourSetTourIsRunning,
  userDefaultProject,
}) => {
  let urlParams = useParams();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  const handleLogin = useCallback(async () => {
    try {
      return await getIdTokenClaims();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error ', error);
    }
  }, [getIdTokenClaims]);

  useEffect(() => {
    if (isAuthenticated) {
      const isUserFirstLogin = getIsFirstLogin();
      isUserFirstLogin === null ? setIsFirstLogin(true) : setIsFirstLogin(false);

      onLoad();
      handleLogin().then((claims) => {
        if (claims) {
          onLogin(claims);
          onAuth(urlParams);
        }
      });
    }
  }, [handleLogin, isAuthenticated, onAuth, onLoad, onLogin, urlParams]);

  if (preloadComplete && moduleUser.accessToken && isAuthenticated && moduleStatus === STATUS.OK) {
    const projectId = userDefaultProject != null ? userDefaultProject : Object.keys(projects)[0];

    const isUserFirstLogin = getIsFirstLogin();

    if (isUserFirstLogin) {
      tourSetTourIsRunning(true);
      return <Redirect to={`/projects/${projectId}`} />;
    }

    if (projectId) {
      return <Redirect to={`/articles-feed/${projectId}`} />;
    }
  }

  if (moduleStatus === 'NO_ORG') {
    return <Redirect to={'/errors/no-org'} />;
  }

  return <></>;
};

LoginCallback.propTypes = {
  onCallback: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  onLoad: PropTypes.func,
  onAuth: PropTypes.func,
  onLogin: PropTypes.func,
  preloadComplete: PropTypes.bool,
  projects: PropTypes.shape({}),
  moduleUser: PropTypes.shape({
    accessToken: PropTypes.string,
    organization: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }),
  moduleStatus: PropTypes.string,
};

const mapStateToProps = (state) => ({
  moduleUser: getUsersCurrentUser(state),
  projects: getProjects(state),
  currentProject: getCurrentProject(state),
  preloadComplete: !getLoadingModule(state).isLoading,
  moduleStatus: getLoadingModule(state).status,
  preloadOngoing: getLoadingModule(state).fetchStarted,
  userDefaultProject: getUsersCurrentUserDefaultProjectId(state),
});

const mapDispatchToProps = (dispatch) => ({
  tourSetTourIsRunning: () => dispatch(tourSetTourIsRunning(true)),
  onLoad: () => {
    dispatch(
      changeModule({
        moduleId: 'loadingModule',
        value: {
          isLoading: true,
          fetchStarted: false,
          fetchCompleted: false,
        },
      }),
    );
  },
  onLogin: (claims) => {
    dispatch(
      receiveUserLogin({
        name: claims.nickname,
        avatar: claims.picture,
        accessToken: claims.__raw,
      }),
    );
    setSession(claims.__raw);
  },
  onAuth: ({ projectId }) => {
    dispatch(
      changeModule({
        moduleId: 'loadingModule',
        value: {
          isLoading: true,
          fetchStarted: true,
          fetchCompleted: false,
        },
      }),
    );
    Promise.all([
      dispatch(fetchCurrentUser()),
      dispatch(fetchProjects()),
      dispatch(fetchClients()),
      dispatch(fetchProducts()),
    ]).then(([user, projects]) => {
      const currentUser = user.payload.items[Object.keys(user.payload.items)[0]];
      if (Object.keys(projects.payload.items).length > 0) {
        const firstProject = projects.payload.items[Object.keys(projects.payload.items)[0]];
        dispatch(changeGlobalForm({ projectId: projectId || firstProject.id }));
      } else {
        dispatch(changeGlobalForm({ projectId: -1 }));
      }
      if (isEmpty(currentUser.organization)) {
        dispatch(
          changeModule({
            moduleId: 'loadingModule',
            value: {
              isLoading: false,
              fetchStarted: true,
              status: 'NO_ORG',
              fetchCompleted: true,
            },
          }),
        );
      } else {
        dispatch(
          changeModule({
            moduleId: 'loadingModule',
            value: {
              isLoading: false,
              fetchStarted: true,
              status: STATUS.OK,
              fetchCompleted: true,
            },
          }),
        );
      }
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginCallback);
