import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import uuid from 'uuid/v1';

import { changeForm, changeGlobalForm, changeNewReportForm } from '../actions/forms';
import { getIsFirstLogin } from '../helpers/localStorage';
import Articles from '../pages/Articles/Articles';
import Clients from '../pages/Brands/Brands';
import ArticlesFeed from '../pages/Media Feeds/Articles/ArticlesFeed.jsx';
import NewReport from '../pages/NewReport/NewReport';
import OrganizationDetails from '../pages/OrganizationDetails/OrganizationDetails';
import Projects from '../pages/Projects/Projects';
import ReportContainer from '../pages/Reports/ReportDetails/ReportDetails';
import Reports from '../pages/Reports/Reports';
import SavedStreams from '../pages/SavedStreams/SavedStreams.jsx';
import SavedVideos from '../pages/SavedVideos/SavedVideos.jsx';
import SettingsContainer from '../pages/Settings/Settings';
import Supersearch from '../pages/SuperSearch/SuperSearch.jsx';
import Tags from '../pages/Tags/Tags';
import TwitchFeed from '../pages/TwitchFeed/TwitchFeed.jsx';
import Websites from '../pages/Websites/Websites';
import WebsiteDetails from '../pages/Websites/WebsitesDetails/WebsiteDetails';
import YoutubeFeed from '../pages/YoutubeFeed/YoutubeFeed.jsx';
import { getGlobalFormFields } from '../selectors/forms';
import {
  getOrganizationPermissionsHasData,
  getOrganizationPermissionsHasTwitchFeed,
  getOrganizationPermissionsHasYoutubeFeed,
} from '../selectors/permissions';
import { getUsersCurrentUserDefaultProjectId, getUsersCurrentUserIsAdmin } from '../selectors/users';

const renderWithHandleProject = (component, handleProject, projectId) => (routeProps) => {
  if (routeProps.match.params.projectId !== 'null' && routeProps.match.params.projectId) {
    handleProject(routeProps.match.params.projectId);
    return <>{component}</>;
  } else {
    handleProject(projectId);
    return <>{component}</>;
  }
};

const ICORoutes = ({
  projectId,
  handleProject,
  user,
  userIsAdmin,
  userHasData,
  userHasYoutubeFeed,
  userHasTwitchFeed,
}) => {
  const isICOUser = user.organization.id === 1;
  const userIsICOAdmin = userIsAdmin && isICOUser;
  const userHasOrganizationCustomer = user.organization?.stripe_customer_id;
  const userOrganizationTrialHasExpired = user.organization?.subscription_status?.trial_expired;

  const getUserErrorRedirects = () => {
    if (isICOUser) {
      return null;
    } else {
      if (userOrganizationTrialHasExpired) {
        return <Redirect to="/errors/end-of-trial" />;
      }

      if (!userHasOrganizationCustomer) {
        return <Redirect to="/errors/no-customer" />;
      }
    }
  };

  const getUserLoginRedirect = () => {
    if (getIsFirstLogin()) {
      return <Route path="/" exact component={Projects} />;
    }
    return <Route exact path="/" component={ArticlesFeed} />;
  };

  const addYoutubeFeedRoutes = () => {
    if (userHasYoutubeFeed) {
      return [
        <Route
          key={uuid()}
          exact
          path="/youtube-feed/:projectId"
          render={renderWithHandleProject(<YoutubeFeed />, handleProject, projectId)}
        />,
        <Route
          exact
          path="/videos/:projectId"
          render={renderWithHandleProject(<SavedVideos />, handleProject, projectId)}
          key={uuid()}
        />,
      ];
    }
  };

  const addTwitchFeedRoutes = () => {
    if (userHasTwitchFeed) {
      return [
        <Route
          key={uuid()}
          exact
          path="/twitch-feed/:projectId"
          render={renderWithHandleProject(<TwitchFeed />, handleProject, projectId)}
        />,
        <Route
          exact
          path="/streams/:projectId"
          render={renderWithHandleProject(<SavedStreams />, handleProject, projectId)}
          key={uuid()}
        />,
      ];
    }
  };

  return (
    <Switch>
      {getUserErrorRedirects()}
      {getUserLoginRedirect()}
      {/*Pages*/}
      <Route path="/settings" exact component={SettingsContainer} />
      {userHasData ? (
        <Route
          path="/supersearch/:projectId"
          exact
          render={(routeProps) => {
            handleProject(routeProps.match.params.projectId);
            return <Supersearch />;
          }}
        />
      ) : null}
      <Route
        exact
        path="/organization-details/:projectId"
        render={renderWithHandleProject(<OrganizationDetails />, handleProject, projectId)}
        key="organization-details"
      />
      <Route path="/brands/:projectId" render={renderWithHandleProject(<Clients />, handleProject, projectId)} />
      <Route path="/projects/:projectId" render={renderWithHandleProject(<Projects />, handleProject, projectId)} />
      <Route path="/tags/:projectId" render={renderWithHandleProject(<Tags />, handleProject, projectId)} />
      <Route path="/new-report/:projectId" render={renderWithHandleProject(<NewReport />, handleProject, projectId)} />
      <Route path="/articles/:projectId" render={renderWithHandleProject(<Articles />, handleProject, projectId)} />
      <Route exact path="/reports/:projectId" render={renderWithHandleProject(<Reports />, handleProject, projectId)} />
      <Route
        exact
        path="/articles-feed/:projectId"
        render={renderWithHandleProject(<ArticlesFeed />, handleProject, projectId)}
      />
      <Route
        exact
        path="/reports/:projectId/:reportId"
        render={(routeProps) => {
          handleProject(routeProps.match.params.projectId);
          return <ReportContainer isPublicReport={false} reportId={routeProps.match.params.reportId} />;
        }}
      />
      {addYoutubeFeedRoutes()}
      {addTwitchFeedRoutes()}
      {userIsICOAdmin && [
        <Route
          path="/websites"
          exact
          render={() => {
            handleProject(projectId);
            return <Websites />;
          }}
          key="admin"
        />,
        <Route
          path="/websites/:websiteUrl"
          render={(routeProps) => {
            handleProject(projectId);
            return <WebsiteDetails domain={routeProps.match.params.websiteUrl} />;
          }}
          key="user"
        />,
      ]}
      <Redirect to="/errors/404" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  projectId: getGlobalFormFields(state).currentProject ?? getUsersCurrentUserDefaultProjectId(state),
  userIsAdmin: getUsersCurrentUserIsAdmin(state),
  userHasData: getOrganizationPermissionsHasData(state),
  userHasYoutubeFeed: getOrganizationPermissionsHasYoutubeFeed(state),
  userHasTwitchFeed: getOrganizationPermissionsHasTwitchFeed(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleProject: (projectId) => {
    dispatch(
      changeForm({
        field: 'project',
        value: projectId,
      }),
    );
    dispatch(
      changeNewReportForm({
        field: 'project',
        value: projectId,
      }),
    );
    return dispatch(changeGlobalForm({ projectId }));
  },
});

ICORoutes.propTypes = {
  user: PropTypes.shape({
    organization: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  handleProject: PropTypes.func,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, mapDispatchToProps)(ICORoutes);
