import {
  API_CALL,
  TWITCH_FEED_CLEAN,
  TWITCH_FEED_FETCH_FEED,
  TWITCH_FEED_FILTERS_SET_START_DATE,
  TWITCH_FEED_FILTERS_SET_END_DATE,
  TWITCH_FEED_SAVE_VIDEOS_SHOW_MODAL,
  TWITCH_FEED_FILTERS_SET_MIN_FOLLOWERS,
  TWITCH_FEED_FILTERS_SET_MAX_FOLLOWERS,
  TWITCH_FEED_FILTERS_CLEAR,
  TWITCH_FEED_SAVE_VIDEOS_CLEAN,
  TWITCH_FEED_SET_SLACK_WEBHOOK,
  TWITCH_FEED_SET_SLACK_MIN_SUBSCRIBERS,
  TWITCH_FEED_SET_DISCORD_WEBHOOK,
  TWITCH_FEED_SET_DISCORD_MIN_SUBSCRIBERS,
  TWITCH_FEED_SET_NOTIFICATION_STATUS,
  TWITCH_FEED_SET_KEYWORD,
  TWITCH_FEED_FETCH_FEED_INFO,
} from './actionTypes';
import urlSearchParamsBuilder from '../helpers/urlSearchParamsBuilder';

export const fetchTwitchFeed = ({ twitch_search_id, filters, pagination }) => {
  let endpoint = `twitch_feed/${twitch_search_id}`;
  const twitchFeedUrl = urlSearchParamsBuilder({ filters, pagination, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: TWITCH_FEED_FETCH_FEED,
      endpoint: twitchFeedUrl,
      method: 'GET',
    },
  };
};

export const twitchFeedFetchFeedInfo = (keywordId) => {
  let endpoint = `twitch_feed/${keywordId}/info`;
  const twitchFeedInfoURL = urlSearchParamsBuilder({ filters: null, pagination: null, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: TWITCH_FEED_FETCH_FEED_INFO,
      endpoint: twitchFeedInfoURL,
      method: 'GET',
    },
  };
};

export const twitchFeedTableFiltersSetStartDate = (value) => ({
  type: TWITCH_FEED_FILTERS_SET_START_DATE,
  payload: value,
});

export const twitchFeedTableFiltersSetEndDate = (value) => ({
  type: TWITCH_FEED_FILTERS_SET_END_DATE,
  payload: value,
});

export const twitchFeedClean = () => ({
  type: TWITCH_FEED_CLEAN,
});

export const twitchFeedSaveVideosShowModal = (value) => ({
  type: TWITCH_FEED_SAVE_VIDEOS_SHOW_MODAL,
  payload: value,
});

export const twitchFeedTableFiltersSetMinFollowers = (value) => ({
  type: TWITCH_FEED_FILTERS_SET_MIN_FOLLOWERS,
  payload: value,
});
export const twitchFeedTableFiltersSetMaxFollowers = (value) => ({
  type: TWITCH_FEED_FILTERS_SET_MAX_FOLLOWERS,
  payload: value,
});

export const twitchFeedTableFiltersClean = () => ({
  type: TWITCH_FEED_FILTERS_CLEAR,
});

export const twitchFeedSaveVideosClean = () => ({
  type: TWITCH_FEED_SAVE_VIDEOS_CLEAN,
});

export const twitchFeedSetSlackWebhook = (webhookURL) => ({
  type: TWITCH_FEED_SET_SLACK_WEBHOOK,
  payload: webhookURL,
});

export const twitchFeedSetSlackWebhookMinSubscribers = (subscribers) => ({
  type: TWITCH_FEED_SET_SLACK_MIN_SUBSCRIBERS,
  payload: subscribers,
});

export const twitchFeedSetDiscordWebhook = (webhookURL) => ({
  type: TWITCH_FEED_SET_DISCORD_WEBHOOK,
  payload: webhookURL,
});

export const twitchFeedSetDiscordWebhookMinSubscribers = (subscribers) => ({
  type: TWITCH_FEED_SET_DISCORD_MIN_SUBSCRIBERS,
  payload: subscribers,
});

export const twitchFeedSetNotificationStatus = (status) => ({
  type: TWITCH_FEED_SET_NOTIFICATION_STATUS,
  payload: status,
});

export const twitchFeedSetKeyword = (keyword) => ({
  type: TWITCH_FEED_SET_KEYWORD,
  payload: keyword,
});
