import React, { useEffect } from 'react';

import values from 'lodash/values';
import { Col, FormGroup, Row } from 'reactstrap';

import SingleSelect from '../../../components/ICO/forms/SingleSelect';
import ImageUploader from '../../../components/ICO/uploads/ImageUploader';
import { IMAGES_ROOT } from '../../../config/global-config';
import translate from '../../../helpers/translate';

const NewProjectModalBody = ({
  setProjectName,
  setAvatar,
  setClient,
  changeProjectForm,
  client,
  avatar,
  clients,
  saveAvatar,
}) => {
  useEffect(
    () => () => {
      setProjectName(null);
      setClient(null);
      setAvatar(null);
    },
    [setProjectName, setClient, setAvatar],
  );

  const handleProjectNameChange = (value) => {
    setProjectName(value);
  };

  const handleSelectImage = (data) => {
    changeProjectForm({ field: 'avatar', value: data[0].name });
    setAvatar(data);
    saveAvatar({ images: data, scope: '0;project' });
  };

  const handleChangeClient = (value) => {
    setClient(value);
  };

  const getClientsOptions = () =>
    values(clients)
      .map((client) => ({ value: client.id, label: client.name }))
      .sort((a, b) => a.label.localeCompare(b.label));

  //eslint-disable-next-line
  console.log(getClientsOptions());

  return (
    <>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <input
              type="text"
              onChange={(e) => handleProjectNameChange(e.target.value)}
              placeholder={translate('pages.projects.modals.new_project.project_name_placeholder')}
              className="form-control"
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <SingleSelect
              aria-label="brand-select"
              name="client-select"
              items={getClientsOptions()}
              value={client?.id ?? []}
              placeholder={translate('common.brand')}
              onChange={handleChangeClient}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <ImageUploader
              urlRoot={IMAGES_ROOT}
              onSelectImage={handleSelectImage}
              multiple={false}
              data={avatar ?? {}}
            />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};

export default NewProjectModalBody;
