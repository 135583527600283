import React, { useState } from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { Card, CardBody, Col, Row } from 'reactstrap';

import TwitchFeedModalsKeyword from './Modals/Keyword/TwitchFeedModalsKeyword.jsx';
import ButtonIcon from '../../components/common/ButtonIcon';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import translate from '../../helpers/translate';

const TwitchFeedCreateFeed = ({ project }) => {
  const [isModalOpen, setModalIsOpen] = useState(false);

  return (
    <Row>
      <Col sm={12}>
        <Card className="mt-3 mb-3">
          <FalconCardHeader title={`${project.name} Streams`} light={false} className="mt-2 mb-2" />
          <CardBody className="d-flex flex-center flex-column">
            <Row>
              <Col>
                {translate('pages.twitch_feed.feature_description_first')} {project.name}{' '}
                {translate('pages.twitch_feed.feature_description_second')}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <ButtonIcon
                  aria-label="createButton"
                  name="createFeedButton"
                  icon={faPlusCircle}
                  color="primary"
                  onClick={() => setModalIsOpen(!isModalOpen)}
                >
                  {translate('pages.twitch_feed.create_feed')}
                </ButtonIcon>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <TwitchFeedModalsKeyword isOpen={isModalOpen} onClose={() => setModalIsOpen(false)} project={project} />
    </Row>
  );
};

export default TwitchFeedCreateFeed;
