import React from 'react';

import SuperSearchTableActions from './Table/SuperSearchTableActions.jsx';
import { favIconFormatter } from '../../components/ICO/tables/ArticlesFeedTable';
import {
  langFormatter,
  dateFormatter,
  ScoreFormatter,
  mediaFormatter,
} from '../../components/ICO/tables/helpers/formatters';
import { extractFavicon, removeProtocolFromUrl } from '../../config/url-utils';
import columnsSorter from '../../helpers/columnsSorter';

const columns = [
  {
    dataField: 'url',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    formatter: (row, rowData) => favIconFormatter(extractFavicon(removeProtocolFromUrl(rowData.url))),
    sortFunc: columnsSorter,
  },
  {
    dataField: 'media',
    text: 'Media',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    formatter: mediaFormatter,
    sortFunc: columnsSorter,
  },
  {
    dataField: 'title',
    text: 'Title',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sortFunc: columnsSorter,
    formatter: (row) => (
      <div className="text-truncate" style={{ width: '450px' }}>
        {row}
      </div>
    ),
  },
  {
    dataField: 'description',
    text: 'Description',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sortFunc: columnsSorter,
    formatter: (row) => (
      <div className="text-truncate" style={{ width: '450px' }}>
        {row}
      </div>
    ),
  },
  {
    dataField: 'score',
    text: 'Media score',
    formatter: ScoreFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    align: 'center',
    headerAlign: 'center',
    sort: true,
    sortFunc: columnsSorter,
  },
  {
    align: 'center',
    classes: 'border-0 align-middle fs-0 text-light',
    dataField: 'language',
    formatter: langFormatter,
    headerAlign: 'center',
    headerClasses: 'border-0',
    text: 'Language',
    sortFunc: columnsSorter,
  },
  {
    align: 'center',
    classes: 'border-0 align-middle fs-',
    dataField: 'pub_date',
    formatter: dateFormatter,
    headerAlign: 'center',
    headerClasses: 'border-0',
    text: 'Date',
    sort: true,
    sortFunc: columnsSorter,
  },
  {
    align: 'center',
    dataField: 'actions',
    text: 'Actions',
    formatter: (row, rowData) => <SuperSearchTableActions rowData={rowData} />,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    headerAlign: 'center',
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
];

export default columns;
