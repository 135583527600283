import { values } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { getEntityDataByKey } from '../../components/ICO/tables/helpers/selectors';
import { buildFiltersQuery, genGetParams, STATUS } from '../../config/api-service';
import {
  getArticlesWebsiteForm,
  getNewReportForm,
  getNewReportFormFields,
  getNewReportParams,
  getNewReportParamsByDate,
  getNewReportParamsByLang,
  getNewReportParamsByTag,
  getNewReportParamsByWebsite,
  getNewReportStep0Params,
  getReportParams,
  getReportParamsByDate,
  getReportParamsByLang,
  getReportParamsByTag,
  getReportParamsByWebsite,
  getTableFiltersForm,
  getTableFiltersFormFieldsById,
  getWebsiteParams,
} from '../forms';
import { getLanguages } from '../languages';
import { getArticleLangChart, getArticleTagChart, getModuleById, getModules } from '../modules';
import { getCurrentProject } from '../projects';
import { getReports } from '../reports';
import { getTags } from '../tags';
export const getArticlesGroups = (state) => state.articles.groups.items;
export const getArticlesGroupsStats = (state) => state.articles.groups.stats;
export const getArticlesGroupsList = (state) => state.articles.groups.lists;
export const getArticlesList = (state) => state.articles.articles.lists;
export const getArticles = (state) => state.articles.articles.items;
export const getArticlesStatus = (state) => state.articles.articles.itemsStatus;

const loadArticleGroupsList = (state, fields) => {
  const articleGroupsIds = getArticlesGroupsList(state)[genGetParams(fields)];
  const articles = [];
  if (articleGroupsIds) {
    articleGroupsIds.map((key) => articles.push(getArticlesGroups(state)[key]));
  }
  return articles;
};

export const getArticlesTableFiltersKey = createSelector(
  [getModules, getTableFiltersForm, getCurrentProject],
  (modules, tableFilters, currentProject) => {
    let filtersResult = {
      page: {
        page: modules.articlesTable.pagination.current_page,
        page_size: modules.articlesTable.pagination.page_size,
      },
      filter: {
        project_id: currentProject.id,
        ...buildFiltersQuery(tableFilters.fields),
      },
    };
    if (tableFilters.fields.sort) {
      filtersResult = { ...filtersResult, sort: tableFilters.fields.sort };
    }
    return filtersResult;
  },
);

export const getTableFiltersKey = (tableId) =>
  createSelector([getModuleById(tableId), getTableFiltersFormFieldsById(tableId)], (module, tableFilters) => {
    let filtersResult = {
      page: {
        page: module.pagination.current_page,
        page_size: module.pagination.page_size,
      },
      filter: buildFiltersQuery(tableFilters),
    };
    if (tableFilters.sort) {
      filtersResult = { ...filtersResult, sort: tableFilters.sort };
    }
    return filtersResult;
  });

export const getArticleByKey = (state, key) => state?.articles?.articles?.items?.[key] ?? {};

export const getArticlesStatsByKey = (state, key) => getEntityDataByKey(state, 'articles', 'stats', key);

export const getArticlesGroupsStatusByKey = (state, key) =>
  state.articles.groups.listsStatus[key] ? state.articles.groups.listsStatus[key] : 'KO';

export const getArticlesStatusByKey = (state, key) => getEntityDataByKey(state, 'articles', 'listsStatus', key);

export const getArticlesByLang = (state) => {
  const newReportFields = getNewReportParamsByLang(getNewReportFormFields(state));
  return loadArticleGroupsList(state, newReportFields);
};

export const getArticlesByTag = (state) => {
  const newReportFields = getNewReportParamsByTag(getNewReportFormFields(state));
  return loadArticleGroupsList(state, newReportFields);
};

export const getArticlesByDate = (state) => {
  const newReportFields = getNewReportParamsByDate(getNewReportFormFields(state));
  return loadArticleGroupsList(state, newReportFields);
};

export const getArticlesReportByLang = (state, props) => {
  const newReportFields = getReportParamsByLang(props.reportId);
  return loadArticleGroupsList(state, newReportFields);
};

export const getArticlesReportByTag = (state, props) => {
  const newReportFields = getReportParamsByTag(props.reportId);
  return loadArticleGroupsList(state, newReportFields);
};

export const getArticlesReportByDate = (state, props) => {
  const newReportFields = getReportParamsByDate(props.reportId);
  return loadArticleGroupsList(state, newReportFields);
};

export const getArticlesGroupsStatusByLang = (state) => {
  const newReportFields = getNewReportParamsByLang(getNewReportFormFields(state));
  return getArticlesGroupsStatusByKey(state, genGetParams(newReportFields));
};

export const getArticlesGroupsStatusByTag = (state) => {
  const newReportFields = getNewReportParamsByTag(getNewReportFormFields(state));
  return getArticlesGroupsStatusByKey(state, genGetParams(newReportFields));
};

export const getArticlesReportStatusFiltered = (state, props) => {
  const key = getArticlesReportFilteredKey(state, props);
  return getArticlesStatusByKey(state, key);
};

export const getArticlesWebsiteStatus = (state, props) => {
  const key = getArticlesWebsiteKey(state, props);
  return getArticlesStatusByKey(state, key);
};

export const getArticlesStatusFiltered = (state) => {
  const key = genGetParams(getArticlesTableFiltersKey(state));
  return getArticlesStatusByKey(state, key);
};

export const getArticlesStatsFiltered = (state) => {
  const key = genGetParams(getArticlesTableFiltersKey(state));
  return getArticlesStatsByKey(state, key);
};

export const getArticlesStats = (state) => {
  const currentPage = state.modules.items.articlesTable.pagination.current_page;
  const key = genGetParams({
    page: {
      page: currentPage,
      page_size: 25,
    },
  });
  return getArticlesStatsByKey(state, key);
};

export const getArticlesNewReportStep0Key = (state) => {
  const filters = getNewReportStep0Params(getNewReportFormFields(state));
  const page = state.modules.items.articlesFilteredTable
    ? state.modules.items.articlesFilteredTable.pagination.current_page
    : 1;
  filters.page = {
    page,
    page_size: 5000,
  };
  return genGetParams(filters);
};

export const getArticlesNewReportPreviewKey = (state) => {
  const filters = getNewReportParams(getNewReportFormFields(state));
  const page = state.modules.items.articlesFilteredTable
    ? state.modules.items.articlesFilteredTable.pagination.current_page
    : 1;
  filters.page = {
    page,
    page_size: 5000,
  };
  return genGetParams(filters);
};

export const getArticlesReportFilteredKey = (state, props) => {
  const page = state.modules.items.articlesFilteredTable
    ? state.modules.items.articlesFilteredTable.pagination.current_page
    : 1;
  const filtersTable = getTableFiltersForm(state);
  const pagination = {
    page,
    page_size: 5000,
  };
  const filters = getReportParams({ reportId: props.reportId, filters: filtersTable.fields, pagination });
  filters.page = {
    page,
    page_size: 5000,
  };
  if (filtersTable.fields.sort) {
    filters.sort = filtersTable.fields.sort;
  }
  return genGetParams(filters);
};

export const getArticlesWebsiteKey = (state, props) => {
  const page = state.modules.items.articlesWebsiteTable
    ? state.modules.items.articlesWebsiteTable.pagination.current_page
    : 1;
  const filtersTable = getArticlesWebsiteForm(state);
  const pagination = {
    page,
    page_size: 5000,
  };
  const filters = getWebsiteParams({ websiteId: props.websiteId, filters: filtersTable.fields }, pagination);
  filters.page = {
    page,
    page_size: 5000,
  };
  if (filtersTable.fields.sort) {
    filters.sort = filtersTable.fields.sort;
  }
  return genGetParams(filters);
};

export const getArticlesFilteredStatus = (state) => {
  const key = getArticlesNewReportPreviewKey(state);
  return getArticlesStatusByKey(state, key);
};

export const getArticlesNewReportStep0 = (state) => {
  const key = getArticlesNewReportStep0Key(state);
  const articles = [];
  if (getArticlesList(state)[key]) {
    getArticlesList(state)[key].map((articleId) => articles.push(getArticles(state)[articleId]));
  }
  return articles;
};

export const getArticlesNewReportPreview = (state) => {
  const key = getArticlesNewReportPreviewKey(state);
  const articles = [];
  if (getArticlesList(state)[key]) {
    getArticlesList(state)[key].map((articleId) => articles.push(getArticles(state)[articleId]));
  }
  return articles;
};

export const getArticlesReport = (state, props) => {
  const key = getArticlesReportFilteredKey(state, props);
  const articles = [];
  const articlesListByKey = getArticlesList(state)[key];
  const stateArticles = getArticles(state);

  if (articlesListByKey) {
    articlesListByKey.map((articleId) => articles.push(stateArticles[articleId]));
  }
  return articles;
};

export const getArticlesWebsite = (state, props) => {
  const key = getArticlesWebsiteKey(state, props);
  const articles = [];
  if (getArticlesList(state)[key]) {
    getArticlesList(state)
      [key].reverse()
      .map((articleId) => articles.push(getArticles(state)[articleId]));
  }
  return articles;
};

export const getArticlesWebsiteStats = (state, props) => {
  const key = getArticlesWebsiteKey(state, props);
  return state.articles.articles.stats[key] ? state.articles.articles.stats[key] : {};
};

export const getArticlesFilteredStats = (state) => {
  const newReportFields = getNewReportParams(getNewReportFormFields(state));
  const key = genGetParams(newReportFields);
  return state.articles.articles.stats[key] ? state.articles.articles.stats[key] : {};
};
export const getArticlesReportStats = (state, reportId) => {
  const reportFields = getReportParams({ reportId });
  const key = genGetParams(reportFields);
  return state.articles.articles.stats[key] ? state.articles.articles.stats[key] : {};
};

export const createArticleChart = (articleGroups, dateStart, dateEnd, key = 'count') => {
  let valuesApi = [];
  const articleGroupIds = Object.keys(articleGroups);
  const now = new Date(dateEnd);
  const days = [];
  if (articleGroupIds) {
    articleGroups.map((groups) => {
      valuesApi[groups.id] = Number(groups[key]);
      return groups;
    });

    let date = new Date(dateStart);

    for (date; date <= now; date.setDate(date.getDate() + 1)) {
      let cDate = date.toISOString().substr(0, 10);
      days.push([
        moment(cDate + ' +0000', 'YYYY-MM-DD Z').valueOf(),
        typeof valuesApi[cDate] !== 'undefined' ? valuesApi[cDate] : 0,
      ]);
    }
  }

  return days;
};

export const getCurrentReport = (state, props) => {
  const reports = getReports(state);
  return reports[props.reportId] ? reports[props.reportId] : {};
};

export const getArticlesChartData = createSelector(
  [getArticlesByDate, getNewReportForm],
  (articleGroups, newReportForm) =>
    createArticleChart(articleGroups, newReportForm.fields.from, newReportForm.fields.to),
);

export const getArticlesReportChartData = createSelector(
  [getArticlesReportByDate, getCurrentReport],
  (articleGroups, report) => createArticleChart(articleGroups, report['date-start'], report['date-end']),
);

export const getArticlesReachChartData = createSelector(
  [getArticlesByDate, getNewReportForm],
  (articleGroups, newReportForm) =>
    createArticleChart(articleGroups, newReportForm.fields.from, newReportForm.fields.to, 'reach'),
);

export const getArticlesReportReachChartData = createSelector(
  [getArticlesReportByDate, getCurrentReport],
  (articleGroups, report) => createArticleChart(articleGroups, report['date-start'], report['date-end'], 'reach'),
);

export const getArticlesVisitsChartData = createSelector(
  [getArticlesByDate, getNewReportForm],
  (articleGroups, newReportForm) =>
    createArticleChart(articleGroups, newReportForm.fields.from, newReportForm.fields.to, 'total-visits'),
);

export const getArticlesReportVisitsChartData = createSelector(
  [getArticlesReportByDate, getCurrentReport],
  (articleGroups, report) =>
    createArticleChart(articleGroups, report['date-start'], report['date-end'], 'total-visits'),
);

export const getArticlesStatsByWebsite = (state) => {
  const groupsStats = getArticlesGroupsStats(state);
  const newReportFields = getNewReportParamsByWebsite(getNewReportFormFields(state));
  const key = genGetParams(newReportFields);
  return groupsStats[key] ? groupsStats[key] : {};
};

export const getArticlesReportStatsByWebsite = (state, reportId) => {
  const groupsStats = getArticlesGroupsStats(state);
  const reportFields = getReportParamsByWebsite(reportId);
  const key = genGetParams(reportFields);
  return groupsStats[key] ? groupsStats[key] : {};
};

const createBarChart = (groups, rowKey = 'count', getName = (item) => item.id) =>
  groups
    .filter((item) => Number(item[rowKey]) > 0)
    .map((item) => ({
      name: getName(item),
      y: Number(item[rowKey]),
      count: item.count,
    }))
    .sort((a, b) => b.count - a.count);

const getChartValueKey = (chart) => {
  if (chart.reach_values) {
    return 'reach';
  }
  if (chart.visits_values) {
    return 'total-visits';
  }
  return 'count';
};

export const getArticlesLangChart = createSelector(
  [getArticlesByLang, getLanguages, getArticleLangChart],
  (articleGroups, langs, articleLangChart) =>
    createBarChart(articleGroups, getChartValueKey(articleLangChart), (item) => {
      let lang = langs[item.id];
      if (lang) return lang.name;
      return '';
    }).sort((a, b) => b.y - a.y),
);

export const getArticlesTagChart = createSelector(
  [getArticlesByTag, getTags, getArticleTagChart],
  (articleGroups, tags, articleTagChart) =>
    createBarChart(articleGroups, getChartValueKey(articleTagChart), (item) => {
      let tag = tags[item.id];
      if (tag) return tag.name;
      return '';
    }).sort((a, b) => b.y - a.y),
);

export const getArticlesReportLangChart = createSelector(
  [getArticlesReportByLang, getLanguages, getArticleLangChart],
  (articleGroups, langs, articleLangChart) =>
    createBarChart(articleGroups, getChartValueKey(articleLangChart), (item) => {
      let lang = langs[item.id];
      return lang ? lang.name : '';
    }).sort((a, b) => b.y - a.y),
);

export const getArticlesReportTagChart = createSelector(
  [getArticlesReportByTag, getTags, getArticleTagChart],
  (articleGroups, tags, articleTagChart) =>
    createBarChart(articleGroups, getChartValueKey(articleTagChart), (item) => {
      let tag = tags[item.id];
      return tag ? tag.name : '';
    }).sort((a, b) => b.y - a.y),
);

export const getArticlesReportLangList = createSelector(
  [getArticlesReportByLang, getLanguages],
  (articleGroups, langs) =>
    Object.values(langs).filter((lang) => articleGroups.find((articleGroup) => articleGroup.id === lang.id)),
);

export const getArticlesReportTagList = createSelector([getArticlesReportByTag, getTags], (articleGroups, tags) =>
  Object.values(tags).filter((tag) => articleGroups.find((articleGroup) => articleGroup.id === tag.id)),
);

export const getArticlesByPage = createSelector(
  [getArticles, getArticlesStatus, getArticlesList, getModules],
  (articles, articlesStatus, ids, modules) => {
    const pageArticleIds =
      ids[
        genGetParams({
          page: {
            page: modules.articlesTable.pagination.current_page,
            page_size: 25,
          },
        })
      ];
    if (pageArticleIds) {
      return pageArticleIds.reduce((res, id) => {
        if (articlesStatus[id] === STATUS.OK) {
          return { ...res, [id]: articles[id] };
        }
        return { ...res };
      }, {});
    }
    return [];
  },
);

export const getEntityByTableFilters = (entities, entitiesStatus, ids, tableFilterKey) => {
  let mergedTableFilterKey = {
    ...tableFilterKey,
    filter: {
      ...tableFilterKey.filter,
    },
  };
  if (tableFilterKey.sort) {
    mergedTableFilterKey.sort = tableFilterKey.sort;
  }
  const entityFiltersKey = ids[genGetParams(mergedTableFilterKey)];
  if (entityFiltersKey) {
    return entityFiltersKey.reduce((res, id) => {
      if (entitiesStatus[id] === STATUS.OK) {
        return { ...res, [id]: entities[id] };
      }
      return { ...res };
    }, {});
  }
  return [];
};

export const getArticlesByTableFilters = createSelector(
  [getArticles, getArticlesStatus, getArticlesList, getArticlesTableFiltersKey],
  (articles, articlesStatus, ids, tableFilterKey) =>
    getEntityByTableFilters(articles, articlesStatus, ids, tableFilterKey),
);

export const articlesTableRows = (state) => values(getArticlesByTableFilters(state));

export const getReportSaveStatus = (state) => state.reports.listsStatus.saving;

export const getNewReportFormStatus = createSelector(
  [
    getNewReportForm,
    getArticlesFilteredStatus,
    getArticlesGroupsStatusByLang,
    getArticlesGroupsStatusByTag,
    getReportSaveStatus,
  ],
  (newReportForm, articlesStatus, articlesStatusByLang, articlesStatusByTag, reportSaveStatus) => {
    if (reportSaveStatus !== 'OK' && newReportForm.status === 'STEP 3') {
      return 'STEP 3';
    } else if (reportSaveStatus === 'OK' && newReportForm.status === 'STEP 3') {
      return 'STEP 3 LOADED';
    } else if (
      articlesStatus === 'OK' &&
      articlesStatusByTag === 'OK' &&
      articlesStatusByLang === 'OK' &&
      newReportForm.status === 'STEP 2'
    ) {
      return 'STEP 2 LOADED';
    } else if (
      (articlesStatus === 'LOADING' || articlesStatusByTag === 'LOADING' || articlesStatusByLang === 'LOADING') &&
      newReportForm.status === 'STEP 2'
    ) {
      return 'STEP 2';
    } else if (articlesStatus === 'OK' && newReportForm.status === 'STEP 1') {
      return 'STEP 1 LOADED';
    } else if ((articlesStatus === 'LOADING' || articlesStatus === 'KO') && newReportForm.status === 'STEP 1') {
      return 'STEP 1';
    } else if (
      (articlesStatus === 'OK' || articlesStatusByTag === 'OK' || articlesStatusByLang === 'OK') &&
      newReportForm.status === 'STEP 0'
    ) {
      return 'STEP 0 LOADED';
    }
    return 'STEP 0';
  },
);

export const getArticlesReportsTagListToDisplay = createSelector([getArticlesReportTagList], (articlesTags) =>
  articlesTags.filter((item) => item['has-highlight'] && item['highlight-date']),
);
