import React, { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { connect } from 'react-redux';

import ArticlesFeedTableBulkActions from './ArticlesFeedTableBulkActions.jsx';
import ArticlesFeedTableEditFeed from './ArticlesFeedTableEditFeed.jsx';
import ArticlesFeedTableExportButton from './ArticlesFeedTableExportButton.jsx';
import ArticlesFeedTableFilters from './ArticlesFeedTableFilters.jsx';
import columns from './columns.jsx';
import { articlesFeedClean, articlesFeedSetSortField, fetchArticlesFeed } from '../../../../actions/articlesFeed';
import Table from '../../../../components/ICO/Table/Table.jsx';
import translate from '../../../../helpers/translate';
import {
  getArticlesFeed,
  getArticlesFeedIsLoading,
  getArticlesFeedLatestPull,
  getArticlesFeedPagination,
  getArticlesFeedSortField,
  getArticlesFeedTableFilters,
} from '../../../../selectors/feeds/articlesFeed';
import { getOrganizationId } from '../../../../selectors/organizations';
import { getOrganizationPermissionsHasSimilarWeb } from '../../../../selectors/permissions';
import { getAlert, getCurrentProject } from '../../../../selectors/projects';

dayjs.extend(relativeTime);

const ArticlesFeedTable = ({
  data = [],
  filters,
  pagination,
  isLoading,
  project,
  articlesFeedClean,
  fetchArticlesFeed,
  userHasSimilarWeb,
  articlesFeedSetSortField,
  alert,
  stateSortField,
  latestPull,
}) => {
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    fetchArticlesFeed({
      alert_id: project['alert-id'],
      pagination: { page: 1, pageSize: 25, sortField: 'pub_date' },
    });

    return () => {
      articlesFeedClean();
    };
  }, [fetchArticlesFeed, project, articlesFeedClean]);

  const onTableChange = useCallback(
    (type, { searchText, page, sortField, sortOrder }) => {
      if (type === 'sort') {
        articlesFeedSetSortField(sortField);
      }

      if (sortField === 'is_metacritic') {
        fetchArticlesFeed({
          alert_id: project['alert-id'],
          filters,
          pagination: {
            page,
            pageSize,
            sortField,
            sortOrder: sortOrder === 'asc' ? 'true' : 'false',
          },
        });
      } else if (type === 'search' && searchText) {
        articlesFeedClean();
        fetchArticlesFeed({
          alert_id: project['alert-id'],
          filters: {
            ...filters,
            searchTerm: searchText,
          },
          pagination: {
            page,
            pageSize,
            sortField: 'pub_date',
            sortOrder,
          },
        });
      } else if (searchText) {
        fetchArticlesFeed({
          alert_id: project['alert-id'],
          filters: {
            ...filters,
            searchTerm: searchText,
          },
          pagination: {
            page,
            pageSize,
            sortField: 'pub_date',
            sortOrder,
          },
        });
      } else {
        fetchArticlesFeed({
          alert_id: project['alert-id'],
          filters,
          pagination: {
            page,
            pageSize,
            sortField,
            sortOrder,
          },
        });
      }
    },
    [fetchArticlesFeed, articlesFeedClean, project, filters, pageSize, articlesFeedSetSortField],
  );

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    fetchArticlesFeed({
      alert_id: project['alert-id'],
      filters,
      pagination: {
        page: 1,
        pageSize: newSize,
        sortField: stateSortField,
      },
    });
  };

  const paginationWithExtra = {
    ...pagination,
    pageSize,
    onSizeChange,
  };

  const getSubtitle = () => {
    if (!data || !alert) return null;

    let subtitle = `${translate('articles.subtitle')} ${alert.keywords}`;

    if (latestPull) {
      subtitle += ` - Last run was ${dayjs(latestPull).fromNow()}`;
    }

    return subtitle;
  };

  return (
    <Table
      data={data}
      columns={columns(userHasSimilarWeb)}
      pagination={paginationWithExtra}
      title={`${project.name} Articles`}
      onTableChange={onTableChange}
      rowActions={<ArticlesFeedTableBulkActions />}
      isLoading={isLoading}
      withRowSelection
      filters={<ArticlesFeedTableFilters />}
      exportBtn={<ArticlesFeedTableExportButton />}
      addButton={<ArticlesFeedTableEditFeed />}
      subtitle={getSubtitle()}
    />
  );
};

const mapStateToProps = (state) => ({
  userOrganizationId: getOrganizationId(state),
  userHasSimilarWeb: getOrganizationPermissionsHasSimilarWeb(state),
  project: getCurrentProject(state),
  isLoading: getArticlesFeedIsLoading(state),
  data: getArticlesFeed(state),
  pagination: getArticlesFeedPagination(state),
  filters: getArticlesFeedTableFilters(state),
  alert: getAlert(state, getCurrentProject(state)['alert-id']),
  stateSortField: getArticlesFeedSortField(state),
  latestPull: getArticlesFeedLatestPull(state),
});

export default connect(mapStateToProps, {
  fetchArticlesFeed,
  articlesFeedClean,
  articlesFeedSetSortField,
})(ArticlesFeedTable);
