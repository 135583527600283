import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { connect } from 'react-redux';

import { fetchTwitchFeed, twitchFeedFetchFeedInfo } from '../../../actions/twitchFeed';
import ButtonIcon from '../../../components/common/ButtonIcon';
import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';
import translate from '../../../helpers/translate';
import { getOrganizationId } from '../../../selectors/organizations';
import { getCurrentProject } from '../../../selectors/projects';
import TwitchFeedModalsKeyword from '../Modals/Keyword/TwitchFeedModalsKeyword.jsx';

const useGetFeedStreams = (keyword_id, onSuccess) =>
  useQuery({
    queryKey: [`twitch_feed_${keyword_id}`],
    queryFn: async () => {
      const url = new URL(`${COVERAGE_API_ROOT}/twitch_feed/${keyword_id}/refresh`);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      }

      return await response.json();
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message: 'Getting new streams for your Twitch Feed',
      };
      getToastedNotification({ customMessage });
      const timeoutId = setTimeout(() => {
        onSuccess();
      }, 2000);

      return () => clearTimeout(timeoutId);
    },
    enabled: false,
    onError: () => {
      const customMessage = {
        type: 'error',
        message: 'There was an error getting new streams for your Twitch Feed. Please try again or contact support.',
      };

      getToastedNotification({ customMessage });
    },
  });

const TwitchFeedEditFeedButton = ({ organizationId, currentProject, fetchTwitchFeed, twitchFeedFetchFeedInfo }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const onSuccess = async () => {
    fetchTwitchFeed({
      twitch_search_id: currentProject['twitch-search-id'],
      pagination: {
        page: 1,
        sizePerPage: 10,
        sortField: 'published_at',
      },
      filters: {
        organization_id: organizationId,
      },
    });
    twitchFeedFetchFeedInfo(currentProject['twitch-search-id']);
  };

  const { isLoading, refetch } = useGetFeedStreams(currentProject['twitch-search-id'], onSuccess);

  return (
    <>
      {organizationId === 1 ? (
        <ButtonIcon
          icon="play"
          transform="shrink-3 down-2"
          color="primary"
          size="sm"
          className="d-none d-md-inline-block text-uppercase fp-button"
          onClick={() => refetch()}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Get streams'}
        </ButtonIcon>
      ) : null}
      <ButtonIcon
        icon="pencil"
        transform="shrink-3 down-2"
        color="primary"
        size="sm"
        className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
        onClick={() => setModalOpen(true)}
      >
        {translate('pages.twitch_feed.edit_feed')}
      </ButtonIcon>
      <TwitchFeedModalsKeyword isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

const mapStateToProps = (state) => ({
  organizationId: getOrganizationId(state),
  currentProject: getCurrentProject(state),
});

export default connect(mapStateToProps, {
  fetchTwitchFeed,
  twitchFeedFetchFeedInfo,
})(TwitchFeedEditFeedButton);
