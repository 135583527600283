import React, { useState } from 'react';

import { faLanguage, faThermometerEmpty } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';

import { formatHTMLSymbols } from '../../../../components/ICO/tables/helpers/formatters';
import { getOrganizationId } from '../../../../selectors/organizations';
import MediaFeedAIErrorHandler from '../AI/MediaFeedAIErrorHandler.jsx';
import MediaFeedAISentimentStatusCircle from '../AI/MediaFeedAISentimentStatusCircle.jsx';
import { useSentimentAnalysis, useTranslation } from '../hooks/useAI';
import ArticlesFeedModalsPreview from '../Modals/Preview/ArticlesFeedModalsPreview.jsx';

const ArticlesFeedTablePreview = ({ row, organizationId }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [sentimentData, setSentimentData] = useState(null);
  const [translationData, setTranslationData] = useState(null);

  const tooltipId = `tooltip_${row.id}`;
  const translationMutation = useTranslation();
  const sentimentMutation = useSentimentAnalysis();

  const handleClickTranslate = () => {
    translationMutation.mutate(
      { article_url: row.url, article_title: row.title },
      {
        onSuccess: (data) => {
          setTranslationData(data.data);
        },
      },
    );
  };

  const handleClickGetSentiment = () => {
    sentimentMutation.mutate(
      { article_url: row.url, article_title: row.title },
      {
        onSuccess: (data) => {
          const normalizedResponse = String(data.data).toLowerCase().trim();
          setSentimentData(normalizedResponse);
        },
      },
    );
  };

  if (translationMutation.isLoading || sentimentMutation.isLoading) {
    return <span>Loading...</span>;
  }

  if (translationMutation.isError) {
    return (
      <MediaFeedAIErrorHandler
        row={row}
        message="Error translating the headline"
        onTranslate={handleClickTranslate}
        onSentiment={handleClickGetSentiment}
      />
    );
  }

  if (sentimentMutation.isError) {
    return (
      <MediaFeedAIErrorHandler
        row={row}
        message="Error getting the sentiment for the headline"
        onTranslate={handleClickTranslate}
        onSentiment={handleClickGetSentiment}
      />
    );
  }

  const validOrganizations = organizationId === 1 || organizationId === 110;

  if (validOrganizations && !translationData && !sentimentData) {
    return (
      <>
        <div className="d-flex flex-row">
          {row.language !== 'en' ? (
            <div
              onClick={handleClickTranslate}
              className="cursor-pointer mr-2 d-flex flex-center"
              aria-label="translationIcon"
            >
              <FontAwesomeIcon icon={faLanguage} title="Translate this headline" />
            </div>
          ) : null}
          <div onClick={handleClickGetSentiment} className="cursor-pointer mr-2 d-flex flex-center">
            <FontAwesomeIcon icon={faThermometerEmpty} title="Get sentiment for this article" />
          </div>
          <div className="cursor-pointer text-truncate" onClick={() => setModalOpen(true)} style={{ width: '25vw' }}>
            <span id={tooltipId}>{formatHTMLSymbols(row.title)}</span>
          </div>
          <UncontrolledTooltip placement="right" target={tooltipId}>
            {row.title}
          </UncontrolledTooltip>
          <ArticlesFeedModalsPreview
            articleData={row}
            onClose={() => setModalOpen(false)}
            isOpen={isModalOpen}
            withSave={true}
          />
        </div>
      </>
    );
  }

  if (translationData) {
    const updatedRow = {
      ...row,
      title: translationData,
    };

    return (
      <div className="d-flex flex-row align-items-center">
        {sentimentData ? (
          <MediaFeedAISentimentStatusCircle sentiment={sentimentData} row={row} />
        ) : (
          <div onClick={handleClickGetSentiment} className="cursor-pointer mr-2 d-flex flex-center">
            <FontAwesomeIcon icon={faThermometerEmpty} title="Get sentiment for this article" />
          </div>
        )}
        <div className="cursor-pointer text-truncate" onClick={() => setModalOpen(true)} style={{ width: '25vw' }}>
          <span id={tooltipId}>{updatedRow.title}</span>
        </div>
        <ArticlesFeedModalsPreview
          articleData={row}
          onClose={() => setModalOpen(false)}
          isOpen={isModalOpen}
          withSave={true}
        />
      </div>
    );
  }

  if (sentimentData) {
    return (
      <div className="d-flex flex-row align-items-center">
        {row.language !== 'en' && !translationData ? (
          <div onClick={handleClickTranslate} className="cursor-pointer mr-2 d-flex flex-center">
            <FontAwesomeIcon icon={faLanguage} title="Translate this headline" />
          </div>
        ) : null}
        <MediaFeedAISentimentStatusCircle sentiment={sentimentData} row={row} />
        <div className="cursor-pointer text-truncate" onClick={() => setModalOpen(true)} style={{ width: '25vw' }}>
          <span id={tooltipId}>{row.title}</span>
        </div>
        <ArticlesFeedModalsPreview
          articleData={row}
          onClose={() => setModalOpen(false)}
          isOpen={isModalOpen}
          withSave={true}
        />
      </div>
    );
  }

  return (
    <>
      <div className="cursor-pointer text-truncate" onClick={() => setModalOpen(true)} style={{ width: '25vw' }}>
        <span id={tooltipId}>{formatHTMLSymbols(row.title)}</span>
      </div>
      <UncontrolledTooltip placement="right" target={tooltipId}>
        {row.title}
      </UncontrolledTooltip>
      <ArticlesFeedModalsPreview
        articleData={row}
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        withSave={true}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  organizationId: getOrganizationId(state),
});

export default connect(mapStateToProps, null)(ArticlesFeedTablePreview);
