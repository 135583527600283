import values from 'lodash/values';
import { createSelector } from 'reselect';

import { getClients } from './clients';
import { getCurrentProjectId } from './forms';
import { getReports } from './reports';
import { getTag } from './tags';
import { getUsersCurrentUserDefaultProjectId } from './users';

export const getProjects = (state) => state.projects.items;
export const getAlerts = (state) =>
  state?.alerts?.response?.map((alert) => ({
    id: alert.id,
    ...alert.attributes,
  }));

export const getProject = (state, projectId) => {
  const projects = getProjects(state);
  return projects[projectId] ? projects[projectId] : {};
};

export const getAlert = (state, id) => getAlerts(state)?.find((alert) => parseInt(alert.id) === id) ?? null;

export const getTagProjectId = (state, tagId) => {
  const tag = getTag(state, tagId);
  const project = tag ? getProject(state, tag.project_id) : null;
  return project ? project.id : null;
};

export const getCurrentProject = createSelector(
  [getProjects, getCurrentProjectId, getUsersCurrentUserDefaultProjectId],
  (projects, currentProjectId, defaultProjectId) => {
    const selectedProjectId = currentProjectId || defaultProjectId;

    if (projects[selectedProjectId]) {
      return projects[selectedProjectId];
    }

    return {
      avatar: 'default.png',
      name: 'No project',
    };
  },
);

export const getProjectsData = createSelector([getProjects, getReports, getClients], (projects, reports, clients) =>
  Object.keys(projects).map((project) => {
    const projectData = projects[project];
    let projectReports = values(reports).filter((report) => report.project_id === project.id);
    let clientData = clients[projectData.client_id] || {};
    return Object.assign({ ...projectData }, projectReports, {
      reports_count: projectReports.length,
      client: clientData,
    });
  }),
);

export const getProjectsLocked = (state, locked = true) => {
  let projects = getProjectsData(state);

  return projects
    .filter((project) => {
      if (!locked) return !project.locked;

      return project.locked === locked;
    })
    .map((project) => ({
      ...project,
      link: `/projects/${project.id}`,
    }))
    .sort((a, b) => new Date(b['inserted-at']) - new Date(a['inserted-at']));
};

export const getProjectsListStatusIsLoading = (state) =>
  Object.values(state.projects.listsStatus)[0] === 'LOADING' && state.projects.items.length;
