import React from 'react';

import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { youtubeFeedSetSlackWebhook, youtubeFeedSetSlackWebhookMinSubscribers } from '../../../actions/youtubeFeed';
import translate from '../../../helpers/translate';
import {
  getYoutubeFeedSlackWebhookMinSubscribers,
  getYoutubeFeedSlackWebhookURL,
} from '../../../selectors/feeds/youtubeFeed';

const SlackNotificationsForm = ({
  youtubeFeedSetSlackWebhook,
  youtubeFeedSetSlackWebhookMinSubscribers,
  slackURL,
  slackMinSubs,
}) => {
  const handleChangeSlackWebhook = (value) => {
    youtubeFeedSetSlackWebhook(value);
  };

  const handleChangeSlackSubscribers = (value) => {
    youtubeFeedSetSlackWebhookMinSubscribers(value);
  };

  return (
    <FormGroup>
      <Row>
        <Col xs={6}>
          <Label htmlFor="slackWebhookInput">{translate('pages.youtube_feed.slack_input.label')}</Label>
          <Input
            placeholder={translate('pages.youtube_feed.slack_input.placeholder')}
            name="slackWebhookInput"
            onChange={(event) => handleChangeSlackWebhook(event.target.value)}
            defaultValue={slackURL ?? null}
          />
        </Col>
        <Col xs={6}>
          <Label htmlFor="slackWebhookSubscribers">
            {translate('pages.youtube_feed.slack_subscribers_input.label')}
          </Label>
          <Input
            placeholder={translate('pages.youtube_feed.slack_subscribers_input.placeholder')}
            name="slackWebhookSubscribers"
            onChange={(event) => handleChangeSlackSubscribers(event.target.value)}
            defaultValue={slackMinSubs ?? null}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <span className="text-gray">
            {translate('pages.youtube_feed.slack_webhook_guide')}
            <a
              href="https://api.slack.com/messaging/webhooks"
              target="_blank"
              rel="noreferrer"
              aria-label="Slack webhook guide"
            >
              {translate('pages.youtube_feed.slack_webhook_guide_link')}
            </a>
            {translate('pages.youtube_feed.slack_webhook_guide_2')}
          </span>
        </Col>
      </Row>
    </FormGroup>
  );
};

const mapStateToProps = (state) => ({
  slackMinSubs: getYoutubeFeedSlackWebhookMinSubscribers(state),
  slackURL: getYoutubeFeedSlackWebhookURL(state),
});

export default connect(mapStateToProps, { youtubeFeedSetSlackWebhookMinSubscribers, youtubeFeedSetSlackWebhook })(
  SlackNotificationsForm,
);
