import React, { useEffect } from 'react';

import debounce from 'lodash/debounce';
import { useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Col, Form, FormFeedback, FormGroup, Label, Row } from 'reactstrap';

import TwitchFeedModalsKeywordNotifications from './TwitchFeedModalsKeywordNotifications.jsx';
import { twitchFeedSetKeyword } from '../../../../actions/twitchFeed';
import translate from '../../../../helpers/translate';
import { getCurrentProject } from '../../../../selectors/projects';
import { useGetTwitchFeedInformation } from '../../hooks/useGetTwitchFeedInformation';
import useGetTwitchGames from '../../hooks/useGetTwitchGames';

const TwitchFeedModalsKeywordBody = ({ keyword, setKeyword, setGameId, currentProject, twitchFeedSetKeyword }) => {
  const { data: feedInfo } = useGetTwitchFeedInformation(currentProject['twitch-search-id']);

  const { data, isLoading } = useGetTwitchGames(keyword);
  const queryClient = useQueryClient();

  useEffect(
    () => () => {
      setKeyword(null);
    },
    [setKeyword],
  );

  const onInputChange = (inputText, event) => {
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setKeyword(inputText);
    }

    if (event.action === 'menu-close') {
      queryClient.removeQueries();
    }
  };

  const onChange = (item) => {
    setKeyword(item?.label ?? null);
    twitchFeedSetKeyword(item?.label);
    setGameId(item?.value ?? null);
  };

  const debounceInput = debounce(onInputChange, 500);

  const selectOptions = data
    ? data.data.data.map((item) => ({ value: item.id, label: item.name, image: item.box_art_url }))
    : [];

  return (
    <>
      <Row>
        <Col xs={12}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup>
              <Label for="game_search">{translate('pages.twitch_feed.modals.keyword_input.label')}</Label>
              <Select
                noOptionsMessage={() => translate('pages.twitch_feed.modals.keyword_input.no_options')}
                placeholder={feedInfo?.keyword ?? translate('pages.twitch_feed.modals.keyword_input.placeholder')}
                isClearable={true}
                isLoading={isLoading}
                options={selectOptions}
                onChange={(value) => onChange(value)}
                onInputChange={debounceInput}
                formatOptionLabel={(game) => (
                  <>
                    <img src={game.image} alt="game-image" loading="lazy" />
                    <span className="ml-2">{game.label}</span>
                  </>
                )}
              />
              <FormFeedback>{translate('pages.twitch_feed.modals.keyword_input.feedback')}</FormFeedback>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <TwitchFeedModalsKeywordNotifications />
    </>
  );
};

const mapStateToProps = (state) => ({
  currentProject: getCurrentProject(state),
});

export default connect(mapStateToProps, { twitchFeedSetKeyword })(TwitchFeedModalsKeywordBody);
