import { useQuery } from 'react-query';

import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';

export const useGetYoutubeFeedInformation = (keywordId) =>
  useQuery({
    queryKey: [`youtube_feed_info${keywordId}`],
    queryFn: async () => {
      const url = new URL(`${COVERAGE_API_ROOT}/youtube_feed/${keywordId}/info`);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      }

      return await response.json();
    },
    refetchOnWindowFocus: false,
  });
