import values from 'lodash/values';

export const getClients = (state) => state.clients.items;

export const getClient = (state, clientId) => {
  const clients = getClients(state);
  return clients[clientId] ? clients[clientId] : {};
};

export const getClientsLocked = (state, locked = true) => {
  let clients = values(getClients(state));
  return clients
    .filter((client) => {
      if (!locked) return !client.locked; //Just in case project.locked is null...

      return client.locked === locked;
    })
    .sort((a, b) => new Date(b['inserted-at']) - new Date(a['inserted-at']));
};

export const getClientsTableListStatusIsLoading = (state) =>
  Object.values(state.clients.listsStatus)[0] === 'LOADING' && state.clients.items.length;
