import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import NewProjectModal from './Modals/NewProjectModal.jsx';
import ProjectsTable from './ProjectsTable';
import { fetchAlerts } from '../../actions/alerts_new';
import { fetchClients } from '../../actions/clients';
import { showModal } from '../../actions/forms';
import { fetchProjects } from '../../actions/projects';
import { tourClear, tourSetHasSkipped, tourSetTourIsRunning, tourSetTourNextStep } from '../../actions/tour';
import { projectsPageSteps, style } from '../../helpers/userTour';
import { getCurrentProjectId } from '../../selectors/forms';
import {
  getTourHasCompletedProjectsSteps,
  getTourHasSkipped,
  getTourIsRunning,
  getTourStep,
} from '../../selectors/tour';

const Projects = ({
  fetchClients,
  fetchProjects,
  fetchAlerts,
  isTourRunning,
  tourStep,
  tourSetTourIsRunning,
  tourSetTourNextStep,
  projectId,
  tourClear,
  hasCompletedProjectsTour,
  tourSetHasSkipped,
  hasSkippedTour,
}) => {
  const [isNewProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const history = useHistory();
  const urlParams = useParams();

  useEffect(() => {
    fetchClients();
    fetchProjects();
    fetchAlerts();
    return () => tourClear();
  }, [tourClear, fetchProjects, fetchClients, fetchAlerts]);

  useEffect(() => {
    if (hasCompletedProjectsTour && !hasSkippedTour) {
      history.push(`/articles-feed/${projectId}`);
    }
  }, [hasSkippedTour, projectId, history, hasCompletedProjectsTour, isTourRunning]);

  const handleJoyrideCallback = ({ action, index, status, type }) => {
    if ([EVENTS.STEP_AFTER].includes(type)) {
      // Update state to advance the tour
      tourSetTourNextStep(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([EVENTS.TARGET_NOT_FOUND].includes(type)) {
      tourSetTourIsRunning(false);
    } else if ([STATUS.FINISHED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      tourSetTourIsRunning(false);
    } else if ([STATUS.SKIPPED].includes(status)) {
      tourSetHasSkipped();
    }
  };

  return (
    <>
      <Row noGutters>
        <Col lg={12} className="mb-3 pr-lg-2 mb-3">
          <Joyride
            steps={projectsPageSteps}
            run={isTourRunning}
            callback={handleJoyrideCallback}
            continuous
            showSkipButton
            disableCloseOnEsc
            hideCloseButton
            stepIndex={tourStep}
            styles={style}
          />
          <ProjectsTable
            urlParams={urlParams}
            title="Projects"
            tableId="projectsTable"
            rowsType="projects"
            newButton={true}
            newButtonClick={() => setNewProjectModalOpen(true)}
            archiveSelection={true}
          />
        </Col>
      </Row>
      <NewProjectModal
        isOpen={isNewProjectModalOpen}
        onClose={() => {
          setNewProjectModalOpen(false);
          fetchProjects();
        }}
      />
    </>
  );
};

Projects.propTypes = {
  fetchProjects: PropTypes.func,
  fetchClients: PropTypes.func,
  fetchAlerts: PropTypes.func,
  showModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isTourRunning: getTourIsRunning(state),
  tourStep: getTourStep(state),
  projectId: getCurrentProjectId(state),
  hasCompletedProjectsTour: getTourHasCompletedProjectsSteps(state),
  hasSkippedTour: getTourHasSkipped(state),
});

export default connect(mapStateToProps, {
  showModal,
  fetchProjects,
  fetchClients,
  fetchAlerts,
  tourSetTourIsRunning,
  tourSetTourNextStep,
  tourClear,
  tourSetHasSkipped,
})(Projects);
