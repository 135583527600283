import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React, { StrictMode } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import App from './App';
import config from './auth/authConfig.json';
import Hotjar from './components/ICO/Hotjar/Hotjar.jsx';
import { AUTH_CLIENT_ID, FP_APP_ROOT } from './config/global-config';
import history from './history';
import Main from './Main';
import './helpers/initFA';
import { store } from './store/store';

const onRedirectCallback = (appState) => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const queryClient = new QueryClient();

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    clientId={AUTH_CLIENT_ID}
    redirectUri={FP_APP_ROOT + '/loading/callback'}
    cacheLocation="localstorage"
    onRedirectCallback={onRedirectCallback}
    responseType="token id_token"
    scope="openid profile read:clients create:clients delete:clients edit:clients"
  >
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Main>
          <StrictMode>
            <Hotjar />
            <App />
          </StrictMode>
        </Main>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Auth0Provider>,
  document.getElementById('main'),
);
