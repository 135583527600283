import React, { useState } from 'react';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage';
import { faThermometerEmpty } from '@fortawesome/free-solid-svg-icons/faThermometerEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

import { useTranslation, useSentimentAnalysis } from './hooks/useAI';
import MediaFeedAIErrorHandler from './MediaFeedAIErrorHandler.jsx';
import MediaFeedAISentimentStatusCircle from './MediaFeedAISentimentStatusCircle.jsx';
import { getOrganizationId } from '../../selectors/organizations';

const MediaFeedAI = ({ row, organizationId }) => {
  const [sentimentData, setSentimentData] = useState(null);
  const [translationData, setTranslationData] = useState(null);
  const translationMutation = useTranslation();
  const sentimentMutation = useSentimentAnalysis();

  const handleClickTranslate = () => {
    translationMutation.mutate(
      { article_url: row.media, article_title: row.title.props.article.title },
      {
        onSuccess: (data) => {
          setTranslationData(data.data);
        },
      },
    );
  };

  const handleClickGetSentiment = () => {
    sentimentMutation.mutate(
      { article_url: row.media, article_title: row.title.props.article.title },
      {
        onSuccess: (data) => {
          const normalizedResponse = String(data.data).toLowerCase().trim();
          setSentimentData(normalizedResponse);
        },
      },
    );
  };

  if (translationMutation.isLoading || sentimentMutation.isLoading) {
    return <span>Loading...</span>;
  }

  if (translationMutation.isError) {
    return (
      <MediaFeedAIErrorHandler
        row={row}
        message="Error translating the headline"
        onTranslate={handleClickTranslate}
        onSentiment={handleClickGetSentiment}
      />
    );
  }

  if (sentimentMutation.isError) {
    return (
      <MediaFeedAIErrorHandler
        row={row}
        message="Error getting the sentiment for the headline"
        onTranslate={handleClickTranslate}
        onSentiment={handleClickGetSentiment}
      />
    );
  }

  if (organizationId === 1 && !translationData && !sentimentData) {
    return (
      <>
        <div className="d-flex flex-row">
          {row.language !== 'en' ? (
            <div
              onClick={handleClickTranslate}
              className="cursor-pointer mr-2 d-flex flex-center"
              aria-label="translationIcon"
            >
              <FontAwesomeIcon icon={faLanguage} title="Translate this headline" />
            </div>
          ) : null}
          <div onClick={handleClickGetSentiment} className="cursor-pointer mr-2 d-flex flex-center">
            <FontAwesomeIcon icon={faThermometerEmpty} title="Get sentiment for this article" />
          </div>
          {row.title}
        </div>
      </>
    );
  }

  if (translationData) {
    const updatedRow = {
      ...row,
      title: {
        ...row.title,
        props: {
          ...row.title.props,
          article: {
            ...row.title.props.article,
            title: translationData,
          },
        },
      },
    };

    return (
      <div className="d-flex flex-row align-items-center">
        {sentimentData ? (
          <MediaFeedAISentimentStatusCircle sentiment={sentimentData} row={row} />
        ) : (
          <div onClick={handleClickGetSentiment} className="cursor-pointer mr-2 d-flex flex-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} title="Get sentiment for this article" />
          </div>
        )}
        <span>{updatedRow.title}</span>
      </div>
    );
  }

  if (sentimentData) {
    return (
      <div className="d-flex flex-row align-items-center">
        {row.language !== 'en' && !translationData ? (
          <div onClick={handleClickTranslate} className="cursor-pointer mr-2 d-flex flex-center">
            <FontAwesomeIcon icon={faLanguage} title="Translate this headline" />
          </div>
        ) : null}
        <MediaFeedAISentimentStatusCircle sentiment={sentimentData} row={row} />
        {row.title}
      </div>
    );
  }

  return <>{row.title}</>;
};

const mapStateToProps = (state) => ({
  organizationId: getOrganizationId(state),
});

export default connect(mapStateToProps, null)(MediaFeedAI);
