import React from 'react';

import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { youtubeFeedSetDiscordWebhook, youtubeFeedSetDiscordWebhookMinSubscribers } from '../../../actions/youtubeFeed';
import translate from '../../../helpers/translate';
import {
  getYoutubeFeedDiscordWebhookMinSubscribers,
  getYoutubeFeedDiscordWebhookURL,
} from '../../../selectors/feeds/youtubeFeed';

const DiscordNotificationForm = ({
  youtubeFeedSetDiscordWebhookMinSubscribers,
  youtubeFeedSetDiscordWebhook,
  discordMinSubs,
  discordURL,
}) => {
  const handleChangeDiscordWebhook = (value) => {
    youtubeFeedSetDiscordWebhook(value);
  };

  const handleChangeDiscordSubscribers = (value) => {
    youtubeFeedSetDiscordWebhookMinSubscribers(value);
  };

  return (
    <FormGroup>
      <Row>
        <Col xs={6}>
          <Label htmlFor="discordWebhookInput">{translate('pages.youtube_feed.discord_input.label')}</Label>
          <Input
            placeholder={translate('pages.youtube_feed.discord_input.placeholder')}
            name="discordWebhookInput"
            onChange={(event) => handleChangeDiscordWebhook(event.target.value)}
            defaultValue={discordURL ?? null}
          />
        </Col>
        <Col xs={6}>
          <Label htmlFor="discordWebhookSubscribers">
            {translate('pages.youtube_feed.discord_subscribers_input.label')}
          </Label>
          <Input
            placeholder={translate('pages.youtube_feed.discord_subscribers_input.placeholder')}
            name="discordWebhookSubscribers"
            onChange={(event) => handleChangeDiscordSubscribers(event.target.value)}
            defaultValue={discordMinSubs ?? null}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <span className="text-gray">
            {translate('pages.youtube_feed.discord_webhook_guide')}
            <a
              href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
              target="_blank"
              rel="noreferrer"
              aria-label="Discord webhook guide"
            >
              {translate('pages.youtube_feed.discord_webhook_guide_link')}
            </a>
            {translate('pages.youtube_feed.discord_webhook_guide_2')}
          </span>
        </Col>
      </Row>
    </FormGroup>
  );
};

const mapStateToProps = (state) => ({
  discordMinSubs: getYoutubeFeedDiscordWebhookMinSubscribers(state),
  discordURL: getYoutubeFeedDiscordWebhookURL(state),
});

export default connect(mapStateToProps, { youtubeFeedSetDiscordWebhookMinSubscribers, youtubeFeedSetDiscordWebhook })(
  DiscordNotificationForm,
);
