import React, { cloneElement, useState } from 'react';

import classNames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Card, CardBody } from 'reactstrap';

import Pagination from './Pagination.jsx';
import SearchBar from './SearchBar.jsx';
import FalconCardHeader from '../../common/FalconCardHeader';
import Loading from '../Loading/Loading';

import '../../../assets/scss/theme/Footprints/components/_pagination.scss';
import '../../../assets/scss/theme/Footprints/components/_button.scss';
import '../../../assets/scss/theme/Footprints/components/_table.scss';

const Table = ({
  addButton,
  columns,
  data,
  filters = null,
  isLoading,
  onTableChange,
  pagination,
  rowActions,
  title,
  subtitle,
  withRowSelection = false,
  className,
  exportBtn,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const remote = {
    filter: true,
    pagination: true,
    sort: true,
    cellEdit: false,
  };

  const options = {
    custom: true,
    sizePerPage: pagination?.pageSize,
    sizePerPageList: [10, 25, 50, 100, 250, 500],
    page: pagination?.page,
    totalSize: pagination?.totalItems,
  };

  const handleSelectRows = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row.id]);
    } else {
      setSelectedRows([...selectedRows.filter((currentRows) => currentRows !== row.id)]);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((row) => row.id);
    if (isSelect) {
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    headerColumnStyle: { border: 0, verticalAlign: 'middle' },
    selectColumnStyle: { border: 0, verticalAlign: 'middle' },
    selected: selectedRows,
    onSelect: handleSelectRows,
    onSelectAll: handleOnSelectAll,
    classes: 'checked',
    selectionRenderer: ({ mode, checked }) => (
      <div className="custom-control custom-checkbox">
        <input type={mode} className="custom-control-input" checked={checked} />
        <label className="custom-control-label" />
      </div>
    ),
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          ref={(input) => {
            if (input) {
              input.indeterminate = indeterminate;
            }
          }}
          {...rest}
        />
        <label className="custom-control-label" />
      </div>
    ),
  };

  const renderTableHeader = (searchProps) => {
    if (selectedRows.length) {
      return cloneElement(rowActions, { selectedRows, setSelectedRows });
    } else {
      return (
        <>
          {addButton ? addButton : null}
          {filters ? filters : null}
          <SearchBar {...searchProps} />
          {exportBtn ? cloneElement(exportBtn, { selectedRows, setSelectedRows }) : null}
        </>
      );
    }
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => (
        <Card className="mt-3 mb-3">
          <div className={classNames('table-responsive fp-table', className)}>
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              bootstrap4
              bordered={false}
              search={{ searchFormatted: true }}
              exportCSV
            >
              {(props) => (
                <>
                  <FalconCardHeader title={title} subtitle={subtitle} light={false} className="mt-2 mb-2">
                    {renderTableHeader(props.searchProps)}
                  </FalconCardHeader>
                  <CardBody className="p-0">
                    {isLoading ? <Loading isTable={true} /> : null}
                    <BootstrapTable
                      keyField="id"
                      data={data}
                      columns={columns}
                      classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                      rowClasses="btn-reveal-trigger"
                      headerClasses="bg-200 text-900"
                      onTableChange={onTableChange}
                      remote={remote}
                      loading={isLoading}
                      noDataIndication={() => (
                        <div className="alert alert-info alert-styled-left table-info">No matching data was found.</div>
                      )}
                      selectRow={withRowSelection ? selectRow : undefined}
                      {...props.baseProps}
                      {...paginationTableProps}
                    />
                  </CardBody>
                </>
              )}
            </ToolkitProvider>
          </div>
          <Pagination
            rows={data}
            paginationProps={paginationProps}
            rowsPerPage={options?.sizePerPage}
            rowsPerPageList={options?.sizePerPageList}
            onChange={onTableChange}
            onSizeChange={pagination?.onSizeChange}
          />
        </Card>
      )}
    </PaginationProvider>
  );
};

export default Table;
