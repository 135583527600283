import {
  API_CALL,
  YOUTUBE_FEED_CLEAN,
  YOUTUBE_FEED_FETCH_FEED,
  YOUTUBE_FEED_UPDATE_KEYWORD,
  YOUTUBE_FEED_FILTERS_SET_START_DATE,
  YOUTUBE_FEED_FILTERS_SET_END_DATE,
  YOUTUBE_FEED_SAVE_VIDEOS_SHOW_MODAL,
  YOUTUBE_FEED_FILTERS_SET_MIN_VIEWS,
  YOUTUBE_FEED_FILTERS_SET_MAX_VIEWS,
  YOUTUBE_FEED_FILTERS_CLEAR,
  YOUTUBE_FEED_SAVE_VIDEOS_CLEAN,
  YOUTUBE_FEED_SET_SLACK_WEBHOOK,
  YOUTUBE_FEED_SET_SLACK_MIN_SUBSCRIBERS,
  YOUTUBE_FEED_SET_DISCORD_WEBHOOK,
  YOUTUBE_FEED_SET_DISCORD_MIN_SUBSCRIBERS,
  YOUTUBE_FEEDS_SET_NOTIFICATION_STATUS,
  YOUTUBE_FEED_FETCH_FEED_INFO,
} from './actionTypes';
import urlSearchParamsBuilder from '../helpers/urlSearchParamsBuilder';

export const updateYoutubeFeedKeyword = ({ youtube_search_id, keyword }) => ({
  type: API_CALL,
  meta: {
    type: YOUTUBE_FEED_UPDATE_KEYWORD,
    endpoint: `youtube_feed/${youtube_search_id}`,
    method: 'PATCH',
    payload: {
      youtube_search_id,
      keyword,
    },
  },
});

export const fetchYoutubeFeed = ({ youtube_search_id, filters, pagination }) => {
  let endpoint = `youtube_feed/${youtube_search_id}`;
  const youtubeFeedUrl = urlSearchParamsBuilder({ filters, pagination, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: YOUTUBE_FEED_FETCH_FEED,
      endpoint: youtubeFeedUrl,
      method: 'GET',
    },
  };
};

export const youtubeFeedFetchFeedInfo = (keywordId) => {
  let endpoint = `youtube_feed/${keywordId}/info`;
  const youtubeFeedInfoURL = urlSearchParamsBuilder({ filters: null, pagination: null, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: YOUTUBE_FEED_FETCH_FEED_INFO,
      endpoint: youtubeFeedInfoURL,
      method: 'GET',
    },
  };
};

export const youtubeFeedTableFiltersSetStartDate = (value) => ({
  type: YOUTUBE_FEED_FILTERS_SET_START_DATE,
  payload: value,
});

export const youtubeFeedTableFiltersSetEndDate = (value) => ({
  type: YOUTUBE_FEED_FILTERS_SET_END_DATE,
  payload: value,
});

export const youtubeFeedClean = () => ({
  type: YOUTUBE_FEED_CLEAN,
});

export const youtubeFeedSaveVideosShowModal = (value) => ({
  type: YOUTUBE_FEED_SAVE_VIDEOS_SHOW_MODAL,
  payload: value,
});

export const youtubeFeedTableFiltersSetMinViews = (value) => ({
  type: YOUTUBE_FEED_FILTERS_SET_MIN_VIEWS,
  payload: value,
});
export const youtubeFeedTableFiltersSetMaxViews = (value) => ({
  type: YOUTUBE_FEED_FILTERS_SET_MAX_VIEWS,
  payload: value,
});

export const youtubeFeedTableFiltersClean = () => ({
  type: YOUTUBE_FEED_FILTERS_CLEAR,
});

export const youtubeFeedSaveVideosClean = () => ({
  type: YOUTUBE_FEED_SAVE_VIDEOS_CLEAN,
});

export const youtubeFeedSetSlackWebhook = (webhookURL) => ({
  type: YOUTUBE_FEED_SET_SLACK_WEBHOOK,
  payload: webhookURL,
});

export const youtubeFeedSetSlackWebhookMinSubscribers = (subscribers) => ({
  type: YOUTUBE_FEED_SET_SLACK_MIN_SUBSCRIBERS,
  payload: subscribers,
});

export const youtubeFeedSetDiscordWebhook = (webhookURL) => ({
  type: YOUTUBE_FEED_SET_DISCORD_WEBHOOK,
  payload: webhookURL,
});

export const youtubeFeedSetDiscordWebhookMinSubscribers = (subscribers) => ({
  type: YOUTUBE_FEED_SET_DISCORD_MIN_SUBSCRIBERS,
  payload: subscribers,
});

export const youtubeFeedSetNotificationStatus = (status) => ({
  type: YOUTUBE_FEEDS_SET_NOTIFICATION_STATUS,
  payload: status,
});
