import React, { Fragment, useCallback, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import ChangeProjectButton from './ChangeProjectButton';
import { changeGlobalForm, tableActions } from '../../../actions/forms';
import { changePage } from '../../../actions/modules';
import { getCurrentProject, getProjectsLocked } from '../../../selectors/projects';
import { getUsersCurrentUserDefaultProjectId } from '../../../selectors/users';
import Flex from '../../common/Flex';

const sortProjectsByName = (projects) => [...projects].sort((a, b) => a.name.localeCompare(b.name));

const ProjectsPanelBody = ({ setIsOpenSidePanel }) => {
  const dispatch = useDispatch();
  const currentProject = useSelector(getCurrentProject);
  const projects = useSelector((state) => getProjectsLocked(state, false));
  const defaultProjectId = useSelector(getUsersCurrentUserDefaultProjectId);

  const sortedProjects = useMemo(() => sortProjectsByName(projects), [projects]);

  const handleProjectClick = useCallback(
    (projectId) => {
      dispatch(changeGlobalForm({ projectId }));
      dispatch(changePage('articlesFeedTable', 1));
      dispatch(changePage('articlesTable', 1));
      dispatch(tableActions['articlesFeedTable'].changeForm({ field: 'checkedRows', value: [] }));
      dispatch(tableActions['articlesTable'].changeForm({ field: 'checkedRows', value: [] }));
      dispatch(tableActions['articlesFeedTable'].resetFilterForm());
      dispatch(tableActions['articlesTable'].resetFilterForm());
      setIsOpenSidePanel(false);
    },
    [dispatch, setIsOpenSidePanel],
  );

  return (
    <>
      {sortedProjects.map((project) => (
        <Fragment key={project.id}>
          <Flex className="justify-content-md-between">
            <ChangeProjectButton
              onClick={() => handleProjectClick(project.id)}
              project={project}
              isActive={project.id === currentProject.id}
              isDefaultProject={defaultProjectId && project.id === defaultProjectId.toString()}
            />
          </Flex>
          <hr />
        </Fragment>
      ))}
    </>
  );
};

export default React.memo(ProjectsPanelBody);
