import React, { memo } from 'react';

import values from 'lodash/values';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import uuid from 'uuid/v4';

import { reportCreationArticlesTableColumns, reportDetailsArticlesTableColumn } from './helpers/columns';
import { tableRows } from './helpers/selectors';
import StaticDataTable from './StaticDataTable';
import { showModal } from '../../../actions/forms';
import { colorScore, extractFavicon, removeProtocolFromUrl, extractDomain } from '../../../config/url-utils';
import {
  getOrganizationPermissionsHasScreenshot,
  getOrganizationPermissionsHasSimilarWeb,
} from '../../../selectors/permissions';
import { getCurrentProject } from '../../../selectors/projects';
import { getTag } from '../../../selectors/tags';
import ArticleTableTitle from '../actions/ArticleTableTitle';
import ReportArticleTableActions from '../actions/ReportArticleTableActions';

export const ScoreFormatter = (score) => (
  <Badge color={colorScore(score)} className="rounded-capsule">
    {score}
  </Badge>
);

const mapStateToProps = (state, ownProps) => {
  const userHasSimilarWeb = getOrganizationPermissionsHasSimilarWeb(state);
  const userCanTakeScreenshot = getOrganizationPermissionsHasScreenshot(state);

  const articlesToRows = (articlesRaw) =>
    values(articlesRaw).map((art) => {
      const loadedTag = getTag(state, art.tag_id) || { name: '' };
      return {
        id: art.id,
        key: uuid(),
        title: <ArticleTableTitle article={art} isFeed={false} />,
        tag: loadedTag.name,
        media: removeProtocolFromUrl(art.fullUrl),
        domain: extractDomain(art.fullUrl),
        favIcon: extractFavicon(removeProtocolFromUrl(art.url)),
        muv: art['website-visits'],
        reach: art.reach,
        language: art.language,
        date: art.date,
        action: <ReportArticleTableActions article={art} />,
        url: art.url,
        fullUrl: art.fullUrl,
      };
    });

  return {
    currentProject: getCurrentProject(state),
    title: 'Articles',
    columns: ownProps.reportId
      ? reportDetailsArticlesTableColumn(userHasSimilarWeb)
      : reportCreationArticlesTableColumns(userHasSimilarWeb, userCanTakeScreenshot),
    module: ownProps.reportId ? state.modules.items.articlesReportTable : state.modules.items.articlesNewReportTable,
    rows: ownProps.reportId
      ? articlesToRows(tableRows(state, ownProps).articlesReportTable())
      : articlesToRows(tableRows(state).articlesNewReportTable()),
    ownProps: ownProps,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onFilterOpen: () => {
    dispatch(showModal({ content: 'filtersArticleTable' }));
  },
  onPreview: (artId) => {
    dispatch(showModal({ content: 'previewArticle', contentId: artId }));
  },
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(StaticDataTable));
