import {
  TWITCH_FEED_SET_DISCORD_WEBHOOK,
  TWITCH_FEED_SET_DISCORD_MIN_SUBSCRIBERS,
  TWITCH_FEED_FETCH_FEED_INFO_SUCCESS,
} from '../../actions/actionTypes';

const initialState = {
  discord_webhook_url: null,
  discord_min_subs_notification: null,
};

const slack = (state = initialState, action) => {
  switch (action.type) {
    case TWITCH_FEED_FETCH_FEED_INFO_SUCCESS:
      return {
        ...state,
        discord_min_subs_notification: action.payload?.webhook?.discord_min_subs_notification,
        discord_webhook_url: action.payload?.webhook?.discord_webhook,
      };
    case TWITCH_FEED_SET_DISCORD_WEBHOOK:
      return { ...state, discord_webhook_url: action.payload };
    case TWITCH_FEED_SET_DISCORD_MIN_SUBSCRIBERS:
      return { ...state, discord_min_subs_notification: action.payload };
    default:
      return state;
  }
};

export default slack;
