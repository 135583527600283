import React from 'react';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { fetchProjects } from '../../../../actions/projects';
import translate from '../../../../helpers/translate';
import {
  getTwitchFeedSlackWebhookMinSubscribers,
  getTwitchFeedSlackWebhookURL,
  getTwitchFeedDiscordWebhookURL,
  getTwitchFeedDiscordWebhookMinSubscribers,
  getTwitchFeedCurrentKeyword,
  getTwitchFeedAreNotificationsEnabled,
  getTwitchFeedIsFormEqual,
} from '../../../../selectors/feeds/twitchFeed';
import { getCurrentProject } from '../../../../selectors/projects';
import useCreateUpdateKeyword from '../../hooks/useCreateUpdateKeyword';
import { useGetTwitchFeedInformation } from '../../hooks/useGetTwitchFeedInformation';

const TwitchFeedModalsKeywordFooter = ({
  onClose,
  keyword,
  project,
  slackWebhookUrl,
  slackMinSubsNotification,
  discordWebhookUrl,
  discordMinSubsNotification,
  gameId,
  notificationsEnabled,
  isFormEqual,
  fetchProjects,
}) => {
  const { refetch } = useGetTwitchFeedInformation(project['twitch-search-id']);

  const projectHasKeyword = project['twitch-search-id'];

  const onCreateSuccess = () => {
    onClose();
    setTimeout(() => {
      fetchProjects();
    }, 1000);
  };

  const mutation = useCreateUpdateKeyword({ onCreateSuccess, method: projectHasKeyword ? 'PATCH' : 'POST' });

  const getButtonIsDisabled = () => {
    if (!project['twitch-search-id'] && !gameId) {
      return true;
    }

    if (isFormEqual) {
      return true;
    }
  };

  const onClick = async () => {
    let params = {
      keyword,
      project_id: project.id,
      game_id: parseInt(gameId),
      twitch_search_id: projectHasKeyword,
      is_enabled: projectHasKeyword ? notificationsEnabled : true,
    };

    if (slackWebhookUrl) {
      params = { ...params, slack_webhook_url: slackWebhookUrl };
    }

    if (slackMinSubsNotification) {
      params = { ...params, slack_min_subs_notification: slackMinSubsNotification };
    }

    if (discordWebhookUrl) {
      params = { ...params, discord_webhook_url: discordWebhookUrl };
    }

    if (discordMinSubsNotification) {
      params = { ...params, discord_min_subs_notification: discordMinSubsNotification };
    }

    mutation.mutate(params);
    onClose();
    await refetch();
  };

  return (
    <>
      <Button size="sm" onClick={onClose} className="fp-button-close">
        {translate('common.close')}
      </Button>
      <Button
        size="sm"
        type="submit"
        onClick={onClick}
        aria-label="save-button"
        color="primary"
        name="saveButton"
        disabled={getButtonIsDisabled()}
      >
        {projectHasKeyword ? translate('common.update') : translate('common.save')}
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  slackWebhookUrl: getTwitchFeedSlackWebhookURL(state),
  slackMinSubsNotification: getTwitchFeedSlackWebhookMinSubscribers(state),
  discordWebhookUrl: getTwitchFeedDiscordWebhookURL(state),
  discordMinSubsNotification: getTwitchFeedDiscordWebhookMinSubscribers(state),
  currentKeyword: getTwitchFeedCurrentKeyword(state),
  notificationsEnabled: getTwitchFeedAreNotificationsEnabled(state),
  isFormEqual: getTwitchFeedIsFormEqual(state),
});

export default connect(mapStateToProps, { fetchProjects })(TwitchFeedModalsKeywordFooter);
