export const API_CALL = 'API_CALL';

export const SUPERSEARCH_FETCH_DATA_PAGINATED = 'SUPERSEARCH@FETCH_DATA_PAGINATED';
export const SUPERSEARCH_FETCH_DATA_PAGINATED_SUCCESS = 'SUPERSEARCH@FETCH_DATA_PAGINATED/SUCCESS';
export const SUPERSEARCH_FETCH_DATA_PAGINATED_ERROR = 'SUPERSEARCH@FETCH_DATA_PAGINATED/ERROR';
export const SUPERSEARCH_FETCH_DATA_PAGINATED_FAILURE = 'SUPERSEARCH@FETCH_DATA_PAGINATED/FAILURE';
export const SUPERSEARCH_SET_SEARCTHERM = 'SUPERSEARCH@SET_SEARCHTERM';
export const SUPERSEARCH_SET_FROMDATE = 'SUPERSEARCH@SET_FROMDATE';
export const SUPERSEARCH_SET_TODATE = 'SUPERSEARCH@SET_TODATE';
export const SUPERSEARCH_SET_LANGUAGES = 'SUPERSEARCH@SET_LANGUAGES';
export const SUPERSEARCH_SET_MIN_SCORE = 'SUPERSEARCH@SET_MIN_SCORE';
export const SUPERSEARCH_SET_MAX_SCORE = 'SUPERSEARCH@SET_MAX_SCORE';

export const SUPERSEARCH_CLEAR = 'SUPERSEARCH_CLEAR';
export const SUPERSEARCH_TABLE_CHANGE_PAGE = 'SUPERSEARCH@TABLE/CHANGE_PAGE';
export const SUPERSEARCH_TABLE_CHANGE_PAGE_SIZE = 'SUPERSERACH@TABLE/CHANGE_PAGE_SIZE';
export const SUPERSEARCH_TABLE_FIRST_PAGE = 'SUPERSEARCH@TABLE/FIRST_PAGE';
export const SUPERSEARCH_FETCH_VOLUME = 'SUPERSEARCH@FETCH_VOLUME';
export const SUPERSEARCH_FETCH_VOLUME_SUCCESS = 'SUPERSEARCH@FETCH_VOLUME/SUCCESS';
export const SUPERSEARCH_FETCH_VOLUME_ERROR = 'SUPERSEARCH@FETCH_VOLUME/ERROR';
export const SUPERSEARCH_CLEAR_VOLUME_DATA = 'SUPERSEARCH@CLEAR_VOLUME_DATA';
export const SUPERSEARCH_CLEAR_TABLE_DATA = 'SUPERSEARCH@CLEAR_TABLE_DATA';

export const SETTINGS_ADD_ORGANISATION = 'SETTINGS@ADD_ORGANISATION';
export const SETTINGS_ADD_ORGANIZATION_SUCCESS = 'SETTINGS@ADD_ORGANISATION/SUCCESS';
export const SETTINGS_ADD_ORGANIZATION_ERROR = 'SETTINGS@ADD_ORGANISATION/ERROR';
export const SETTINGS_ADD_ORGANIZATION_FAILURE = 'SETTINGS@ADD_ORGANISATION/FAILURE';
export const SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS = 'SETTINGS@FETCH_CUSTOMER_ORGANIZATIONS';
export const SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS_SUCCESS = 'SETTINGS@FETCH_CUSTOMER_ORGANIZATIONS/SUCCESS';
export const SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS_ERROR = 'SETTINGS@FETCH_CUSTOMER_ORGANIZATIONS/ERROR';
export const SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS_FAILURE = 'SETTINGS@FETCH_CUSTOMER_ORGANIZATIONS/FAILURE';
export const SETTINGS_CLEAR = 'SETTINGS@CLEAR';

export const SIGNUP_FREE_USER_CREATE_BRAND = 'SIGNUP_FREE_USER@ADD_BRAND';
export const SIGNUP_FREE_USER_CREATE_BRAND_SUCCESS = 'SIGNUP_FREE_USER@ADD_BRAND/SUCCESS';
export const SIGNUP_FREE_USER_CREATE_BRAND_ERROR = 'SIGNUP_FREE_USER@ADD_BRAND/ERROR';

export const SIGNUP_FREE_USER_CREATE_PROJECT = 'SIGNUP_FREE_USER@CREATE_PROJECT';
export const SIGNUP_FREE_USER_CREATE_PROJECT_SUCCESS = 'SIGNUP_FREE_USER@CREATE_PROJECT/SUCCESS';
export const SIGNUP_FREE_USER_CREATE_PROJECT_ERROR = 'SIGNUP_FREE_USER@CREATE_PROJECT/ERROR';

export const SIGNUP_FREE_USER_CREATE_USER_ACCOUNT = 'SIGNUP_FREE_USER@CREATE_USER_ACCOUNT';
export const SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_SUCCESS = 'SIGNUP_FREE_USER@CREATE_USER_ACCOUNT/SUCCESS';
export const SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_ERROR = 'SIGNUP_FREE_USER@CREATE_USER_ACCOUNT/ERROR';

export const SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER = 'SIGNUP_FREE_USER@CREATE_STRIPE_CUSTOMER';
export const SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_SUCCESS = 'SIGNUP_FREE_USER@CREATE_STRIPE_CUSTOMER/SUCCESS';
export const SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_ERROR = 'SIGNUP_FREE_USER@CREATE_STRIPE_CUSTOMER/ERROR';

export const SIGNUP_FREE_USER_CLEAR = 'SIGNUP_FREE_USER@CLEAR';

export const UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT = 'UPGRADE_SUBSCRIPTION@STRIPE_CHECKOUT';
export const UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT_SUCCESS = 'UPGRADE_SUBSCRIPTION@STRIPE_CHECKOUT/SUCCESS';
export const UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT_ERROR = 'UPGRADE_SUBSCRIPTION@STRIPE_CHECKOUT/ERROR';
export const UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT_FAILURE = 'UPGRADE_SUBSCRIPTION@STRIPE_CHECKOUT/FAILURE';
export const UPGRADE_SUBSCRIPTION_SET_PRODUCT_ID = 'UPGRADE_SUBSCRIPTION@SET_PRODUCT_ID';
export const UPGRADE_SUBSCRIPTION_CLEAR = 'UPGRADE_SUBSCRIPTION@CLEAR';

export const REPORTS_CLEAN = 'REPORTS@CLEAN';
export const REPORTS_SCREENSHOT_ADD_HIGHLIGHTED_ARTICLE = 'REPORTS_SCREENSHOT@ADD_HIGHLIGHTED_ARTICLE';
export const REPORTS_SCREENSHOT_CLEAR = 'REPORTS_SCREENSHOT@CLEAR_HIGHLIGHTED_ARTICLE';
export const REPORTS_SCREENSHOT_REMOVE_HIGHLIGHTED_ARTICLE = 'REPORTS_SCREENSHOT@REMOVE_HIGHLIGHTED_ARTICLE';
export const REPORTS_FETCH_VIDEOS = 'REPORTS@FETCH_VIDEOS';
export const REPORTS_FETCH_VIDEOS_SUCCESS = 'REPORTS@FETCH_VIDEOS/SUCCESS';
export const REPORTS_FETCH_VIDEOS_ERROR = 'REPORTS@FETCH_VIDEOS/ERROR';
export const REPORTS_FETCH_VIDEOS_FAILURE = 'REPORTS@FETCH_VIDEOS/FAILURE';
export const REPORTS_FETCH_STREAMS = 'REPORTS@FETCH_STREAMS';
export const REPORTS_FETCH_STREAMS_SUCCESS = 'REPORTS@FETCH_STREAMS/SUCCESS';
export const REPORTS_FETCH_STREAMS_ERROR = 'REPORTS@FETCH_STREAMS/ERROR';
export const REPORTS_FETCH_STREAMS_FAILURE = 'REPORTS@FETCH_STREAMS/FAILURE';
export const REPORT_VIDEOS_CLEAN = 'REPORTS_VIDEOS@CLEAN';
export const REPORTS_STREAMS_CLEAN = 'REPORTS_STREAMS@CLEAN';

export const PRODUCTS_FETCH_PRODUCTS = 'PRODUCTS@FETCH_PRODUCTS';
export const PRODUCTS_FETCH_PRODUCTS_SUCCESS = 'PRODUCTS@FETCH_PRODUCTS/SUCCESS';
export const PRODUCTS_FETCH_PRODUCTS_ERROR = 'PRODUCTS@FETCH_PRODUCTS/ERROR';
export const PRODUCTS_FETCH_PRODUCTS_FAILURE = 'PRODUCTS@FETCH_PRODUCTS/FAILURE';

export const MEDIATYPES_FETCH_MEDIATYPES = 'MEDIATYPES@FETCH_MEDIATYPES';
export const MEDIATYPES_FETCH_MEDIATYPES_SUCCESS = 'MEDIATYPES@FETCH_MEDIATYPES/SUCCESS';
export const MEDIATYPES_FETCH_MEDIATYPES_ERROR = 'MEDIATYPES@FETCH_MEDIATYPES/ERROR';
export const MEDIATYPES_FETCH_MEDIATYPES_FAILURE = 'MEDIATYPES@FETCH_MEDIATYPES/FAILURE';

export const TOUR_SET_NEXT_STEP = 'TOUR@SET_NEXT_STEP';
export const TOUR_SET_IS_RUNNING = 'TOUR@SET_IS_RUNNING';
export const TOUR_SET_HAS_COMPLETED_PROJECTS = 'TOUR@SET_HAS_COMPLETED_PROJECTS';
export const TOUR_CLEAR = 'TOUR@CLEAR';
export const TOUR_SET_HAS_SKIPPED = 'TOUR@SET_HAS_SKIPPED';

export const YOUTUBE_FEED_FETCH_FEED = 'YOUTUBE_FEED@FETCH_FEED';
export const YOUTUBE_FEED_FETCH_FEED_SUCCESS = 'YOUTUBE_FEED@FETCH_FEED/SUCCESS';
export const YOUTUBE_FEED_FETCH_FEED_ERROR = 'YOUTUBE_FEED@FETCH_FEED/ERROR';
export const YOUTUBE_FEED_FETCH_FEED_FAILURE = 'YOUTUBE_FEED@FETCH_FEED/FAILURE';
export const YOUTUBE_FEED_FETCH_FEED_INFO = 'YOUTUBE_FEED@FETCH_FEED_INFO';
export const YOUTUBE_FEED_FETCH_FEED_INFO_SUCCESS = 'YOUTUBE_FEED@FETCH_FEED_INFO/SUCCESS';
export const YOUTUBE_FEED_CLEAN = 'YOUTUBE_FEED@CLEAN';
export const YOUTUBE_FEED_CREATE_KEYWORD = 'YOUTUBE_FEED@CREATE_KEYWORD';
export const YOUTUBE_FEED_CREATE_KEYWORD_SUCCESS = 'YOUTUBE_FEED@CREATE_KEYWORD/SUCCESS';
export const YOUTUBE_FEED_CREATE_KEYWORD_ERROR = 'YOUTUBE_FEED@CREATE_KEYWORD/ERROR';
export const YOUTUBE_FEED_CREATE_KEYWORD_FAILURE = 'YOUTUBE_FEED@CREATE_KEYWORD/FAILURE';
export const YOUTUBE_FEED_UPDATE_KEYWORD = 'YOUTUBE_FEED@UPDATE_KEYWORD';
export const YOUTUBE_FEED_SET_SLACK_WEBHOOK = 'YOUTUBE_FEED@SET_SLACK_WEBHOOK';
export const YOUTUBE_FEED_SET_SLACK_MIN_SUBSCRIBERS = 'YOUTUBE_FEED@SET_SLACK_MIN_SUBSCRIBERS';
export const YOUTUBE_FEED_SET_DISCORD_WEBHOOK = 'YOUTUBE_FEED@SET_DISCORD_WEBHOOK';
export const YOUTUBE_FEED_SET_DISCORD_MIN_SUBSCRIBERS = 'YOUTUBE_FEED@SET_DISCORD_MIN_SUBSCRIBERS';
export const YOUTUBE_FEED_FILTERS_SET_START_DATE = 'YOUTUBE_FEED_FILTERS@SET_START_DATE';
export const YOUTUBE_FEED_FILTERS_SET_END_DATE = 'YOUTUBE_FEED_FILTERS@SET_END_DATE';
export const YOUTUBE_FEED_FILTERS_SET_MIN_VIEWS = 'YOUTUBE_FEED_FILTERS@SET_MIN_VIEWS';
export const YOUTUBE_FEED_FILTERS_SET_MAX_VIEWS = 'YOUTUBE_FEED_FILTERS@SET_MAX_VIEWS';
export const YOUTUBE_FEED_FILTERS_CLEAR = 'YOUTUBE_FEED_FILTERS@CLEAR';
export const YOUTUBE_FEED_SAVE_VIDEOS_CLEAN = 'YOUTUBE_FEED@SAVE_VIDEOS/CLEAN';
export const YOUTUBE_FEED_SAVE_VIDEOS_SHOW_MODAL = 'YOUTUBE_FEED@SAVE_VIDEOS_SHOW_MODAL';

export const YOUTUBE_FEEDS_SET_NOTIFICATION_STATUS = 'YOUTUBE_FEED@SET_NOTIFICATION_STATUS';
export const SAVED_VIDEOS_FETCH_VIDEOS = 'SAVED_VIDEOS@FETCH_VIDEOS';
export const SAVED_VIDEOS_FETCH_VIDEOS_SUCCESS = 'SAVED_VIDEOS@FETCH_VIDEOS/SUCCESS';
export const SAVED_VIDEOS_FETCH_VIDEOS_ERROR = 'SAVED_VIDEOS@FETCH_VIDEOS/ERROR';
export const SAVED_VIDEOS_FETCH_VIDEOS_FAILURE = 'SAVED_VIDEOS@FETCH_VIDEOS/FAILURE';
export const SAVED_VIDEOS_FILTERS_SET_LANGUAGES = 'SAVED_VIDEOS_FILTERS@SET_LANGUAGES';
export const SAVED_VIDEOS_FILTERS_SET_TAGS = 'SAVED_VIDEOS_FILTERS@SET_TAGS';
export const SAVED_VIDEOS_FILTERS_SET_START_DATE = 'SAVED_VIDEOS_FILTERS@SET_START_DATE';
export const SAVED_VIDEOS_FILTERS_SET_END_DATE = 'SAVED_VIDEOS_FILTERS@SET_END_DATE';
export const SAVED_VIDEOS_DELETE_VIDEOS = 'SAVED_VIDEOS@DELETE_VIDEOS';
export const SAVED_VIDEOS_DELETE_VIDEOS_SUCCESS = 'SAVED_VIDEOS@DELETE_VIDEOS/SUCCESS';
export const SAVED_VIDEOS_DELETE_VIDEOS_ERROR = 'SAVED_VIDEOS@DELETE_VIDEOS/ERROR';
export const SAVED_VIDEOS_DELETE_VIDEOS_FAILURE = 'SAVED_VIDEOS@DELETE_VIDEOS/FAILURE';
export const SAVED_VIDEOS_DELETE_CLEAN = 'SAVED_VIDEOS@DELETE/CLEAN';
export const SAVED_VIDEOS_CLEAN = 'SAVED_VIDEOS@CLEAN';
export const SAVED_VIDEOS_FILTERS_CLEAN = 'SAVED_VIDEOS_FILTERS@CLEAN';

export const SAVED_VIDEOS_SET_PAGE_SIZE = 'SAVED_VIDEOS@SET_PAGE_SIZE';
export const TWITCH_FEED_FETCH_FEED = 'TWITCH_FEED@FETCH_FEED';
export const TWITCH_FEED_FETCH_FEED_SUCCESS = 'TWITCH_FEED@FETCH_FEED/SUCCESS';
export const TWITCH_FEED_FETCH_FEED_ERROR = 'TWITCH_FEED@FETCH_FEED/ERROR';
export const TWITCH_FEED_FETCH_FEED_FAILURE = 'TWITCH_FEED@FETCH_FEED/FAILURE';
export const TWITCH_FEED_FETCH_FEED_INFO = 'TWITCH_FEED@FETCH_FEED_INFO';
export const TWITCH_FEED_FETCH_FEED_INFO_SUCCESS = 'TWITCH_FEED@FETCH_FEED_INFO/SUCCESS';
export const TWITCH_FEED_CLEAN = 'TWITCH_FEED@CLEAN';
export const TWITCH_FEED_SET_SLACK_WEBHOOK = 'TWITCH_FEED@SET_SLACK_WEBHOOK';
export const TWITCH_FEED_SET_SLACK_MIN_SUBSCRIBERS = 'TWITCH_FEED@SET_SLACK_MIN_SUBSCRIBERS';
export const TWITCH_FEED_SET_DISCORD_WEBHOOK = 'TWITCH_FEED@SET_DISCORD_WEBHOOK';
export const TWITCH_FEED_SET_DISCORD_MIN_SUBSCRIBERS = 'TWITCH_FEED@SET_DISCORD_MIN_SUBSCRIBERS';
export const TWITCH_FEED_FILTERS_SET_START_DATE = 'TWITCH_FEED_FILTERS@SET_START_DATE';
export const TWITCH_FEED_FILTERS_SET_END_DATE = 'TWITCH_FEED_FILTERS@SET_END_DATE';
export const TWITCH_FEED_FILTERS_SET_MIN_FOLLOWERS = 'TWITCH_FEED_FILTERS@SET_MIN_FOLLOWERS';
export const TWITCH_FEED_FILTERS_SET_MAX_FOLLOWERS = 'TWITCH_FEED_FILTERS@SET_MAX_FOLLOWERS';
export const TWITCH_FEED_FILTERS_CLEAR = 'TWITCH_FEED_FILTERS@CLEAR';
export const TWITCH_FEED_SAVE_VIDEOS_CLEAN = 'TWITCH_FEED@SAVE_VIDEOS/CLEAN';
export const TWITCH_FEED_SAVE_VIDEOS_SHOW_MODAL = 'TWITCH_FEED@SAVE_VIDEOS_SHOW_MODAL';
export const TWITCH_FEED_SET_NOTIFICATION_STATUS = 'TWITCH_FEED@SET_NOTIFICATION_STATUS';
export const TWITCH_FEED_SET_KEYWORD = 'TWITCH_FEED@SET_KEYWORD';

export const SAVED_STREAMS_FETCH_STREAMS = 'SAVED_STREAMS@FETCH_STREAMS';
export const SAVED_STREAMS_FETCH_STREAMS_SUCCESS = 'SAVED_STREAMS@FETCH_STREAMS/SUCCESS';
export const SAVED_STREAMS_FETCH_STREAMS_ERROR = 'SAVED_STREAMS@FETCH_STREAMS/ERROR';
export const SAVED_STREAMS_FETCH_STREAMS_FAILURE = 'SAVED_STREAMS@FETCH_STREAMS/FAILURE';
export const SAVED_STREAMS_FILTERS_SET_LANGUAGES = 'SAVED_STREAMS_FILTERS@SET_LANGUAGES';
export const SAVED_STREAMS_FILTERS_SET_TAGS = 'SAVED_STREAMS_FILTERS@SET_TAGS';
export const SAVED_STREAMS_FILTERS_SET_START_DATE = 'SAVED_STREAMS_FILTERS@SET_START_DATE';
export const SAVED_STREAMS_FILTERS_SET_END_DATE = 'SAVED_STREAMS_FILTERS@SET_END_DATE';
export const SAVED_STREAMS_FILTERS_SET_MIN_FOLLOWERS = 'SAVED_STREAMS_FILTERS@SET_MIN_FOLLOWERS';
export const SAVED_STREAMS_FILTERS_SET_MAX_FOLLOWERS = 'SAVED_STREAMS_FILTERS@SET_MAX_FOLLOWERS';

export const SAVED_STREAMS_SET_PAGE_SIZE = 'SAVED_STREAMS@SET_PAGE_SIZE';
export const SAVED_STREAMS_CLEAN = 'SAVED_STREAMS@CLEAN';
export const SAVED_STREAMS_FILTERS_CLEAN = 'SAVED_STREAMS_FILTERS@CLEAN';

export const FETCH_REGIONS = 'FETCH_REGIONS';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS/SUCCESS';
export const FETCH_REGIONS_ERROR = 'FETCH_REGIONS/ERROR';
export const FETCH_REGIONS_FAILURE = 'FETCH_REGIONS/FAILURE';

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES/SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES/ERROR';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES/FAILURE';

export const PROJECTS_SET_QUERY = 'PROJECTS@SET_QUERY';

export const FETCH_ALERTS = 'FETCH_ALERTS';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS/SUCCESS';
export const FETCH_ALERTS_ERROR = 'FETCH_ALERTS/ERROR';
export const FETCH_ALERTS_FAILURE = 'FETCH_ALERTS/FAILURE';

export const ARTICLES_FEED_FETCH_FEED = 'ARTICLES_FEED@FETCH_FEED';
export const ARTICLES_FEED_FETCH_FEED_SUCCESS = 'ARTICLES_FEED@FETCH_FEED/SUCCESS';
export const ARTICLES_FEED_FETCH_FEED_ERROR = 'ARTICLES_FEED@FETCH_FEED/ERROR';
export const ARTICLES_FEED_FETCH_FEED_FAILURE = 'ARTICLES_FEED@FETCH_FEED/FAILURE';
export const ARTICLES_FEED_CLEAN = 'ARTICLES_FEED@CLEAN';
export const ARTICLES_FEED_FILTERS_CLEAR = 'ARTICLES_FEED_FILTERS@CLEAR';
export const ARTICLES_FEED_FILTERS_SET_LANGUAGES = 'ARTICLES_FEED_FILTERS@SET_LANGUAGES';
export const ARTICLES_FEED_FILTERS_SET_METACRITIC = 'ARTICLES_FEED_FILTERS@SET_METACRITIC';
export const ARTICLES_FEED_FILTERS_SET_MIN_MEDIA_SCORE = 'ARTICLES_FEED_FILTERS@SET_MIN_MEDIA_SCORE';
export const ARTICLES_FEED_FILTERS_SET_MAX_MEDIA_SCORE = 'ARTICLES_FEED_FILTERS@SET_MAX_MEDIA_SCORE';
export const ARTICLES_FEED_FILTERS_SET_START_DATE = 'ARTICLES_FEED_FILTERS@SET_START_DATE';
export const ARTICLES_FEED_FILTERS_SET_END_DATE = 'ARTICLES_FEED_FILTERS@SET_END_DATE';
export const ARTICLES_FEED_SET_SORT_FIELD = 'ARTICLES_FEED@SET_SORT_FIELD';
